import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_appointment_badge_reportServiceInParams {
  dockAppointmentId: number}

interface IReports_custom_appointment_badge_reportServiceData {
  DockAppointments?: { result?: { Id?: number, CheckedInOn?: string, DriverLicense?: string, LookupCode?: string, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledCarrier?: { Name?: string, ShortName?: string } }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_appointment_badge_reportService extends ReportBaseService<IReports_custom_appointment_badge_reportServiceInParams, IReports_custom_appointment_badge_reportServiceData> {

  protected reportReferenceName = 'custom_appointment_badge_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('Reports', 'custom_appointment_badge_report');
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_appointment_badge_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_appointment_badge_reportServiceInParams): Promise<IReports_custom_appointment_badge_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_appointment_badge_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_appointment_badge_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        dockAppointmentId:  $report.inParams.dockAppointmentId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_dock_appointment_details_report.get(dsParams);
      
      data.DockAppointments = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
