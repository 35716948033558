import { Inject, Injectable, Injector } from '@angular/core';


import { Reports_custom_appointment_badge_reportService } from './Reports.report.index';
import { Reports_custom_cycle_count_reportService } from './Reports.report.index';
import { Reports_custom_label_cencoraService } from './Reports.report.index';
import { Reports_custom_label_eversanaService } from './Reports.report.index';
import { Reports_custom_label_mckcvsService } from './Reports.report.index';
import { Reports_custom_label_walmartService } from './Reports.report.index';
import { Reports_custom_license_plate_report_by_serialIdService } from './Reports.report.index';
import { Reports_custom_outbound_labels_reportService } from './Reports.report.index';
import { Reports_custom_packing_list_reportService } from './Reports.report.index';
import { Reports_custom_packing_slip_reportService } from './Reports.report.index';
import { Reports_custom_receiving_reportService } from './Reports.report.index';
import { Reports_custom_transaction_reportService } from './Reports.report.index';
import { Reports_custom_vector_labelService } from './Reports.report.index';

@Injectable({ providedIn: 'root' })
export class Reports_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_appointment_badge_report: Reports_custom_appointment_badge_reportService;
  public get custom_appointment_badge_report(): Reports_custom_appointment_badge_reportService {
    if(!this._custom_appointment_badge_report) {
      this._custom_appointment_badge_report = this.injector.get(Reports_custom_appointment_badge_reportService);
    }
    return this._custom_appointment_badge_report;
  }
  private _custom_cycle_count_report: Reports_custom_cycle_count_reportService;
  public get custom_cycle_count_report(): Reports_custom_cycle_count_reportService {
    if(!this._custom_cycle_count_report) {
      this._custom_cycle_count_report = this.injector.get(Reports_custom_cycle_count_reportService);
    }
    return this._custom_cycle_count_report;
  }
  private _custom_label_cencora: Reports_custom_label_cencoraService;
  public get custom_label_cencora(): Reports_custom_label_cencoraService {
    if(!this._custom_label_cencora) {
      this._custom_label_cencora = this.injector.get(Reports_custom_label_cencoraService);
    }
    return this._custom_label_cencora;
  }
  private _custom_label_eversana: Reports_custom_label_eversanaService;
  public get custom_label_eversana(): Reports_custom_label_eversanaService {
    if(!this._custom_label_eversana) {
      this._custom_label_eversana = this.injector.get(Reports_custom_label_eversanaService);
    }
    return this._custom_label_eversana;
  }
  private _custom_label_mckcvs: Reports_custom_label_mckcvsService;
  public get custom_label_mckcvs(): Reports_custom_label_mckcvsService {
    if(!this._custom_label_mckcvs) {
      this._custom_label_mckcvs = this.injector.get(Reports_custom_label_mckcvsService);
    }
    return this._custom_label_mckcvs;
  }
  private _custom_label_walmart: Reports_custom_label_walmartService;
  public get custom_label_walmart(): Reports_custom_label_walmartService {
    if(!this._custom_label_walmart) {
      this._custom_label_walmart = this.injector.get(Reports_custom_label_walmartService);
    }
    return this._custom_label_walmart;
  }
  private _custom_license_plate_report_by_serialId: Reports_custom_license_plate_report_by_serialIdService;
  public get custom_license_plate_report_by_serialId(): Reports_custom_license_plate_report_by_serialIdService {
    if(!this._custom_license_plate_report_by_serialId) {
      this._custom_license_plate_report_by_serialId = this.injector.get(Reports_custom_license_plate_report_by_serialIdService);
    }
    return this._custom_license_plate_report_by_serialId;
  }
  private _custom_outbound_labels_report: Reports_custom_outbound_labels_reportService;
  public get custom_outbound_labels_report(): Reports_custom_outbound_labels_reportService {
    if(!this._custom_outbound_labels_report) {
      this._custom_outbound_labels_report = this.injector.get(Reports_custom_outbound_labels_reportService);
    }
    return this._custom_outbound_labels_report;
  }
  private _custom_packing_list_report: Reports_custom_packing_list_reportService;
  public get custom_packing_list_report(): Reports_custom_packing_list_reportService {
    if(!this._custom_packing_list_report) {
      this._custom_packing_list_report = this.injector.get(Reports_custom_packing_list_reportService);
    }
    return this._custom_packing_list_report;
  }
  private _custom_packing_slip_report: Reports_custom_packing_slip_reportService;
  public get custom_packing_slip_report(): Reports_custom_packing_slip_reportService {
    if(!this._custom_packing_slip_report) {
      this._custom_packing_slip_report = this.injector.get(Reports_custom_packing_slip_reportService);
    }
    return this._custom_packing_slip_report;
  }
  private _custom_receiving_report: Reports_custom_receiving_reportService;
  public get custom_receiving_report(): Reports_custom_receiving_reportService {
    if(!this._custom_receiving_report) {
      this._custom_receiving_report = this.injector.get(Reports_custom_receiving_reportService);
    }
    return this._custom_receiving_report;
  }
  private _custom_transaction_report: Reports_custom_transaction_reportService;
  public get custom_transaction_report(): Reports_custom_transaction_reportService {
    if(!this._custom_transaction_report) {
      this._custom_transaction_report = this.injector.get(Reports_custom_transaction_reportService);
    }
    return this._custom_transaction_report;
  }
  private _custom_vector_label: Reports_custom_vector_labelService;
  public get custom_vector_label(): Reports_custom_vector_labelService {
    if(!this._custom_vector_label) {
      this._custom_vector_label = this.injector.get(Reports_custom_vector_labelService);
    }
    return this._custom_vector_label;
  }
}

