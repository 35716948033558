import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inventory_transfer_form',
  templateUrl: './FootPrintManager.inventory_transfer_form.component.html'
})
export class FootPrintManager_inventory_transfer_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { lotId: number, packagingId: number, licenseplateId: number, packagedAmount: number, materialId: number, availablePackagedAmount: number } = { lotId: null, packagingId: null, licenseplateId: null, packagedAmount: null, materialId: null, availablePackagedAmount: null };
  //#region Inputs
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  @Input('packagingId') set $inParams_packagingId(v: number) {
    this.inParams.packagingId = v;
  }
  get $inParams_packagingId(): number {
    return this.inParams.packagingId;
  }
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  @Input('packagedAmount') set $inParams_packagedAmount(v: number) {
    this.inParams.packagedAmount = v;
  }
  get $inParams_packagedAmount(): number {
    return this.inParams.packagedAmount;
  }
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('availablePackagedAmount') set $inParams_availablePackagedAmount(v: number) {
    this.inParams.availablePackagedAmount = v;
  }
  get $inParams_availablePackagedAmount(): number {
    return this.inParams.availablePackagedAmount;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { warehouseId?: number, sourceProjectId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    requested_packaged_amount: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '', null)
    )
  };

  fields = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Target owner', true),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Target project', true),
    requested_packaged_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['requested_packaged_amount'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Packaged amount to transfer', true),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', true),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.owner.control.value;
    
    return expr;
  }

  cacheValueFor_$fields_project_selector_inParams_excludedProjectIds: number[];
  get $fields_project_selector_inParams_excludedProjectIds(): number[] {
    const $form = this;
    const $utils = this.utils;
    const expr = [$form.vars.sourceProjectId];
    
    if(!isEqual(this.cacheValueFor_$fields_project_selector_inParams_excludedProjectIds, expr)) {
      this.cacheValueFor_$fields_project_selector_inParams_excludedProjectIds = expr;
    }
    return this.cacheValueFor_$fields_project_selector_inParams_excludedProjectIds;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    requested_packaged_amount: this.fields.requested_packaged_amount.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.lotId)) {
        this.$missingRequiredInParams.push('lotId');
      }
      if(isNil(this.inParams.packagingId)) {
        this.$missingRequiredInParams.push('packagingId');
      }
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
      if(isNil(this.inParams.packagedAmount)) {
        this.$missingRequiredInParams.push('packagedAmount');
      }
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
      if(isNil(this.inParams.availablePackagedAmount)) {
        this.$missingRequiredInParams.push('availablePackagedAmount');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Transfer Inventory';
  
    const $form = this;
    const $utils = this.utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .requested_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_inventory_transfer_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $form.outParams.confirm = false;
  $form.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_inventory_transfer_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.requested_packaged_amount.control.value,
      $form.fields.project.control.value,
      $form.fields.notes.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $shell.FootPrintManager.openErrorDialog('Inventory Transfer Error', 'Please enter in data in all required fields.');
      return;
  }
  if ($form.fields.requested_packaged_amount.control.value > $form.inParams.availablePackagedAmount) {
  
      throw new Error('Requested packaged amount to transfer is greater than the available amount of ' + $form.inParams.availablePackagedAmount.toString());
  }
  
  
  if ($form.inParams.availablePackagedAmount <= 0) {
      throw new Error('You cannot transfer more than the remaining available value')
  }
  
  
  // Handle double click scenario
  $form.toolbar.confirm.control.readOnly = true;
  
  //Get base packaging details
  const fetchBasePackagingDetails = await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
      materialId: $form.inParams.materialId,
      packagingId: $form.inParams.packagingId
  });
  const basePackagingDetails = fetchBasePackagingDetails.result
  const basePackaging = basePackagingDetails[0].BasePackagingId
  const baseAmount = basePackagingDetails[0].BasePackagingQuantity
  
  //Check if serial controlled
  const fetchMaterialResult = await $datasources.Materials.ds_get_material_by_materialId.get({
      materialId: $form.inParams.materialId
  });
  const materials = fetchMaterialResult.result;
  if (materials[0].ControllerTypeId === 3 || materials[0].ControllerTypeId === 4 || materials[0].IsFixedWeight === false) {
      $form.toolbar.confirm.control.readOnly = false;
      throw new Error('Adjusting serial controlled inventory not yet available.');
  }
  
  // Initialize lot source data
  const fetchLotResult = await $datasources.Lots.ds_get_lot_by_lotId.get({
      lotId: $form.inParams.lotId
  });
  const lots = fetchLotResult.result;
  if ($utils.isDefined(lots)) {
      var lotLookupcode = lots[0].LookupCode;
      var vendorLookupcode = lots[0].VendorLot.LookupCode;
      var expirationDate = lots[0].VendorLot.ExpirationDate;
      var manufactureDate = lots[0].VendorLot.ManufactureDate;
      var vat = lots[0].VendorLot.Vat;
  }
  
  // Initialize material source data
  const materialLookup = materials[0].LookupCode;
  const materialDescription = materials[0].Description;
  const materialName = materials[0].Name;
  const materialControllerType = materials[0].ControllerTypeId;
  const isFixedWeight = materials[0].IsFixedWeight
  const materialGroupId = materials[0].MaterialGroupId;
  const allocationStrategyId = materials[0].AllocationStrategyId
  const allocationStrategyWorkflowId = materials[0].AllocationStrategyWorkflowId
  const lotId = $form.inParams.lotId;
  var packagedId = $form.inParams.packagingId;
  const licensePlateId = $form.inParams.licenseplateId;
  const requestedpackagedamount = parseInt($form.fields.requested_packaged_amount.control.value);
  
  // Initialize  source location and licenseplate data
  const sourcelocation = (await $datasources.Inventory.ds_get_licenseplates_by_licenseplateIds.get({ licenseplateIds: [licensePlateId] })).result;
  const sourceLocationid = sourcelocation[0].LocationId;
  var warehouseId = sourcelocation[0].WarehouseId;
  
  // Initialize data to remove from the source inventory
  const delta = $form.inParams.packagedAmount - requestedpackagedamount
  
  
  
  // Check if the material exists for the target project if not create it
  const targetMaterial = (await $datasources.Materials.ds_get_material_by_lookupcode_and_projectId.get({
      projectId: $form.fields.project.control.value,
      lookupcode: materialLookup
  })).result;
  var targetMaterialId;
  
  if ($utils.isDefined(targetMaterial)) {
  
      targetMaterialId = targetMaterial[0].Id;
  
      // Make sure the target packaging exists
      const targetPackaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: targetMaterialId, packagingId: packagedId
      })).result;
      if (!$utils.isDefined(targetPackaging)) {
  
          const base = (await $datasources.Materials.ds_get_materials_basepackaging_by_materialId.get({ materialId: targetMaterialId })).result;
          if ($utils.isDefined(base)) {
  
              var baseTargetPackagingId = base.PackagingId
              try {
                  const payloadMaterialPackaging = {
                      "MaterialId": targetMaterialId,
                      "PackagingId": packagedId,
                      "IsBasePackaging": false,
                      "SubPackagingId": baseTargetPackagingId,
                      "SubPackagingQuantity": baseAmount,
                      "BasePackagingId": baseTargetPackagingId,
                      "BasePackagingQuantity": baseAmount,
  
                      "AllowFractions": true,
                      "AllowInSalesOrder": true,
                      "AllowInPurchaseOrder": true,
                      "IsReportingDefault": true,
                      "IsOrderDefault": false,
                      "IsLabelCompliant": false,
                      "UseAsShippingContainer": false,
                      "DeprecatedPackaging": false,
                      "AllowInMobileSales": false,
  
                      "ShippingWeight": 1,
                      "Weight": 1,
                      "TareWeight": 0,
                      "WeightUomId": 14,
  
                      "Height": 1,
                      "Width": 1,
                      "Length": 1,
                      "DimensionUomId": 5,
  
                      "Volume": 1,
                      "ShippingVolume": 1,
                      "VolumeUomId": 11
  
                  };
  
                  await $flows.Utilities.crud_create_flow({ entitySet: 'MaterialsPackagingsLookup', entity: payloadMaterialPackaging });
              }
              catch (error) {
                  $shell.FootPrintManager.showErrorDetails('Save', 'Error on creating target material packaging.', error);
                  throw error; // to prevent displayMode 
              }
  
  
  
          }
          else {
              throw new Error('Base packaging for target material could not be identified.');
          }
  
  
      }
  } else {
  
  
      try {
          // Determine material controller type
          var lotControlled;
          var serialControlled;
  
          if (materialControllerType === 1) {
              lotControlled = false
              serialControlled = false
          }
  
          else if (materialControllerType === 2) {
              lotControlled = true
              serialControlled = false
          }
  
          else if (materialControllerType === 3) {
              lotControlled = false
              serialControlled = true
          }
  
          else if (materialControllerType === 4) {
              lotControlled = true
              serialControlled = true
          }
  
  
          const materialResult = (await $flows.Materials.create_material_flow({
              projectId: $form.fields.project.control.value,
              lookupcode: materialLookup,
              lotControlled: lotControlled,
              serialControlled: serialControlled,
              materialGroupId: materialGroupId,
              description: materialDescription,
              name: materialName,
              allocationStrategyId: allocationStrategyId,
              allocationStrategyWorkflowId: allocationStrategyWorkflowId,
              isFixedWeight: isFixedWeight,
              isFixedLength: true,
              isFixedVolume: true,
              shelfLifeSpan: 0,
              basePackagingId: packagedId,
              netWeight: 1,
              shippingWeight: 1,
              weightUomId: 12,
              length: 1,
              width: 1,
              dimensionUomId: 3,
              netVolume: 1,
              shippingVolume: 1,
              volumeUomId: 7
  
          }))
  
          if ($utils.isDefined(materialResult.materialId)) {
  
              targetMaterialId = materialResult.materialId;
  
              let copyUdf = (await $flows.Materials.copy_material_udf_flow(
                  {
                      sourceMaterialId: $form.inParams.materialId,
                      targetMaterialId: targetMaterialId
                  }
              ));
              if ($utils.isDefined(copyUdf.reason)) {
                  $shell.Materials.openErrorDialog('Error copy material udfs', copyUdf.reason);
              }
  
          }
          else {
              $shell.Materials.openErrorDialog('Material Creation Error', 'Unable to create the requested material.');
              return;
  
          }
      }
      catch (error) {
          $shell.FootPrintManager.showErrorDetails('Save', 'Error on creation of target material', error);
          throw error; // to prevent displayMode 
      }
  }
  
  // Check if the lot exists for the target material
  const targetLot = (await $datasources.Lots.ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode.get({
      materialId: targetMaterialId,
      lotLookupcode: lotLookupcode,
      vendorLookupcode: vendorLookupcode
  })).result;
  var targetLotId;
  if ($utils.isDefined(targetLot)) {
  
      targetLotId = targetLot[0].Id;
  } else {
  
      var nextVendorLotId;
      const targetVendorLot = (await $datasources.Lots.ds_get_vendorlot_by_lookupcode_and_materialId.get({
          materialId: targetMaterialId,
          vendorLookupcode: vendorLookupcode
      })).result;
      if ($utils.isDefined(targetVendorLot)) {
          nextVendorLotId = targetVendorLot[0].Id;
      }
      else {
          nextVendorLotId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'VendorLot' })).nextId;
  
          const payloadVendorLot = {
  
              "Id": nextVendorLotId,
              "MaterialId": targetMaterialId,
              "LookupCode": vendorLookupcode,
              "ManufactureDate": $utils.isDefined(manufactureDate) ? manufactureDate : $utils.date.now(),
              "ExpirationDate": $utils.isDefined(expirationDate) ? expirationDate : $utils.date.now(),
              "Vat": $utils.isDefined(vat) ? vat : ''
  
          };
  
          await $flows.Utilities.crud_create_flow({ entitySet: 'VendorLots', entity: payloadVendorLot });
  
  
      }
  
      const nextLotId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Lot' })).nextId;
      const payloadLot = {
  
          "Id": nextLotId,
          "MaterialId": targetMaterialId,
          "TypeId": 2,
          "LookupCode": lotLookupcode,
          "VendorLotId": nextVendorLotId,
          "StatusId": 1
  
      };
  
      await $flows.Utilities.crud_create_flow({ entitySet: 'Lots', entity: payloadLot });
      targetLotId = nextLotId;
  }
  
  
  // Check if the lot already exists in the source LP, if so add to it else add a new row
  // Subtract inventory from the original lot
  // Check if the result is 0, if so delete the row
  // subtract inventory from source location
  
  try {
      // Check if inventory exists in the license plates for the target lot
      const inventories = (await $datasources.Inventory.ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId.get({
          licenseplateId: licensePlateId,
          lotId: targetLotId,
          packagingId: packagedId
      })).result;
      if ($utils.isDefined(inventories)) {
          // Add to existing license plate contents
          const licensePlatePayload = {
              "PackagedAmount": requestedpackagedamount + inventories[0].PackagedAmount,
              "Amount": (requestedpackagedamount * baseAmount) + inventories[0].Amount
          };
          await $flows.Utilities.crud_update_triple_pk_flow({
              entitySet: 'LicensePlateContents',
              entity: licensePlatePayload,
              pk1String: 'LicensePlateId',
              pk1: licensePlateId,
              pk2String: 'LotId',
              pk2: targetLotId,
              pk3String: 'PackagedId',
              pk3: packagedId
          });
  
      } else {
          // Else create new inventory
          const licensePlateContentsPayload = {
              "LicensePlateId": licensePlateId,
              "LotId": targetLotId,
              "PackagedId": packagedId,
              "PackagedAmount": requestedpackagedamount,
              "Amount": baseAmount * requestedpackagedamount,
              "FirstTimeLotReceivedOnLicensePlateDate": $utils.date.now()
          };
          await $flows.Utilities.crud_create_flow({ entitySet: 'LicensePlateContents', entity: licensePlateContentsPayload });
  
  
      }
      // Subtract inventory from source license plate
      const licensePlatePayload = {
          "PackagedAmount": delta,
          "Amount": (delta * baseAmount)
      };
      await $flows.Utilities.crud_update_triple_pk_flow({
          entitySet: 'LicensePlateContents',
          entity: licensePlatePayload,
          pk1String: 'LicensePlateId',
          pk1: licensePlateId,
          pk2String: 'LotId',
          pk2: lotId,
          pk3String: 'PackagedId',
          pk3: packagedId
      });
  
  
      // Check if source inventory is at zero then archive if
      const sourceinventories = (await $datasources.Inventory.ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId.get({
          licenseplateId: licensePlateId,
          lotId: lotId,
          packagingId: packagedId
      })).result;
      if ($utils.isDefined(sourceinventories)) {
  
          if (sourceinventories[0].Amount == 0 && sourceinventories[0].PackagedAmount == 0) {
  
              await $flows.Utilities.crud_delete_triple_pk_flow({
                  entitySet: 'LicensePlateContents',
                  pk1String: 'LicensePlateId',
                  pk1: licensePlateId,
                  pk2String: 'LotId',
                  pk2: lotId,
                  pk3String: 'PackagedId',
                  pk3: packagedId
              });
  
  
          }
      }
  
      // Create transfer task source project
  
  
      const nextTaskId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Task' })).nextId;
      const user = (await $flows.Utilities.get_username_flow({})).userName;
  
      const taskPayload = {
          "Id": nextTaskId,
          "ChainHead": 0,
          "StatusId": 2,
          "WarehouseId": warehouseId,
          "OperationCodeId": 135, // Inventory Transfer
          "ProjectId": materials[0].ProjectId,
          "MaterialId": $form.inParams.materialId,
          "LotId": $form.inParams.lotId,
          "Employee": user,
          "ExpectedInventoryAmount": -1 * (baseAmount * requestedpackagedamount),
          "ExpectedInventoryAmountPackId": basePackaging,
          "ExpectedPackagedAmount": -1 * (requestedpackagedamount),
          "ExpectedPackagedPackId": $form.inParams.packagingId,
          "ActualInventoryAmount": -1 * (baseAmount * requestedpackagedamount),
          "ActualInventoryAmountPackId": basePackaging,
          "ActualPackagedAmount": -1 * (requestedpackagedamount),
          "ActualPackagedPackId": $form.inParams.packagingId,
          "NetWeight": 1,
          "GrossWeight": 1,
          "WeightUomId": 14,
          "CreatedSysDateTime": $utils.date.now(),
          "StartDateTime": $utils.date.now(),
          "CompletedDateTime": $utils.date.now(),
          "ExpectedSourceLocationId": sourceLocationid,
          "ActualSourceLocationId": sourceLocationid,
          "ExpectedTargetLocationId": sourceLocationid,
          "ActualTargetLocationId": sourceLocationid,
          "ActualTargetLicensePlateId": licensePlateId,
          "ExpectedTargetLicensePlateId": licensePlateId,
          "ActualSourceLicensePlateId": licensePlateId,
          "ExpectedSourceLicensePlateId": licensePlateId,
          "GrossVolume": 1,
          "VolumeUomId": 8, // lookup base
          "NetVolume": 1,
          "Notes": $form.fields.notes.control.value
      };
  
      await $flows.Utilities.crud_create_flow({ entitySet: 'Tasks', entity: taskPayload });
  
      // Create transfer task target project
  
      const nextTaskIdTarget = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Task' })).nextId;
  
  
      const taskPayloadTarget = {
          "Id": nextTaskIdTarget,
          "ChainHead": nextTaskId,
          "StatusId": 2,
          "WarehouseId": warehouseId,
          "OperationCodeId": 135,
          "ProjectId": $form.fields.project.control.value,
          "MaterialId": targetMaterialId,
          "LotId": targetLotId,
          "Employee": user,
          "ExpectedInventoryAmount": baseAmount * requestedpackagedamount,
          "ExpectedInventoryAmountPackId": basePackaging,
          "ExpectedPackagedAmount": requestedpackagedamount,
          "ExpectedPackagedPackId": $form.inParams.packagingId,
          "ActualInventoryAmount": baseAmount * requestedpackagedamount,
          "ActualInventoryAmountPackId": basePackaging,
          "ActualPackagedAmount": requestedpackagedamount,
          "ActualPackagedPackId": $form.inParams.packagingId,
          "NetWeight": 1,
          "GrossWeight": 1,
          "WeightUomId": 14,
          "CreatedSysDateTime": $utils.date.now(),
          "StartDateTime": $utils.date.now(),
          "CompletedDateTime": $utils.date.now(),
          "ExpectedSourceLocationId": sourceLocationid,
          "ActualSourceLocationId": sourceLocationid,
          "ExpectedTargetLocationId": sourceLocationid,
          "ActualTargetLocationId": sourceLocationid,
          "ActualTargetLicensePlateId": licensePlateId,
          "ExpectedTargetLicensePlateId": licensePlateId,
          "ActualSourceLicensePlateId": licensePlateId,
          "ExpectedSourceLicensePlateId": licensePlateId,
          "GrossVolume": 1,
          "VolumeUomId": 8, // lookup base
          "NetVolume": 1,
          "Notes": $form.fields.notes.control.value
      };
  
      await $flows.Utilities.crud_create_flow({ entitySet: 'Tasks', entity: taskPayloadTarget });
  
  
      $form.outParams.confirm = true;
      $form.close();
  
  }
  catch (error) {
      $shell.FootPrintManager.showErrorDetails('Save', 'Error creating inventory transfer tasks', error);
      throw error; // to prevent displayMode 
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_inventory_transfer_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  var title;
  
  // Set source projectId and pass into projects selector to filter out
  if ($utils.isDefined($form.inParams.materialId)) {
      const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $form.inParams.materialId })).result;
      if ($utils.isDefined(materials)) {
          $form.vars.sourceProjectId = materials[0].ProjectId;
      }
  
  }
  
  const licenseplates = (await $datasources.Inventory.ds_get_licenseplates_by_licenseplateIds.get({ licenseplateIds: [$form.inParams.licenseplateId] })).result;
  if ($utils.isDefined(licenseplates)) {
      const licenseplate = licenseplates[0].LookupCode.toUpperCase();
      const locationId = licenseplates[0].LocationId;
      // Set warehouse variable
      $form.vars.warehouseId = licenseplates[0].WarehouseId;
  
      const locations = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
      if ($utils.isDefined(locations)) {
          const locationName = locations[0].Name.toUpperCase();
  
          const lots = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: $form.inParams.lotId })).result;
          if ($utils.isDefined(lots)) {
              const lotLookupcode = lots[0].LookupCode.toUpperCase();
              const materialId = lots[0].MaterialId;
  
              const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result;
              if ($utils.isDefined(materials)) {
                  const materialLookupcode = materials[0].LookupCode.toUpperCase();
  
                  const packagings = (await $datasources.Materials.ds_get_material_inventory_measurements_by_unitId.get({ unitId: $form.inParams.packagingId })).result;
                  if ($utils.isDefined(packagings)) {
                      const packagingShortName = packagings[0].ShortName.toUpperCase();
                      title = `Transfer inventory from Location ${locationName} for Licenseplate ${licenseplate} Material ${materialLookupcode} Lot ${lotLookupcode} Packaging ${packagingShortName}`;
  
                  }
              }
          }
      }
  }
  
  // Set title field
  $form.title = title;
  
  // Set focus on owner field
  $form.fields.owner.control.focus();
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: FootPrintManager_inventory_transfer_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $form.fields.project.control.value = null;
  }
  //#endregion private flows
}
