import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_label_cencoraServiceInParams {
  shippingContainerId: number}

interface IReports_custom_label_cencoraServiceData {
  label?: { result?: { Id?: number, LookupCode?: string, Shipment?: { LookupCode?: string, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string, Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }[], Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string } }, ContainerType?: { Description?: string, Name?: string }, deliver_to?: { Id?: number, AlternateFax?: string, AlternateTelephone?: string, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Name?: string, PostalCode?: string, PrimaryFax?: string, PrimaryTelephone?: string, State?: string, Title?: string }, first7?: string, ord4char?: string } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_label_cencoraService extends ReportBaseService<IReports_custom_label_cencoraServiceInParams, IReports_custom_label_cencoraServiceData> {

  protected reportReferenceName = 'custom_label_cencora';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('Reports', 'custom_label_cencora');
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_label_cencoraServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_label_cencoraServiceInParams): Promise<IReports_custom_label_cencoraServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_label_cencoraServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_label_cencoraServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        shippingContainerId:  $report.inParams.shippingContainerId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_outbound_label_by_shippingContainerId.get(dsParams);
      
      data.label = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
