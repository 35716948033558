import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_get_carton_info_by_taskIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId?: number, chainHead?: number }): 
  Promise<{ result: { Id?: number, SerialNumber?: { Id?: number, LookupCode?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/get_carton_info_by_taskId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { taskId?: number, chainHead?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, SerialNumber?: { Id?: number, LookupCode?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/get_carton_info_by_taskId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { taskId?: number, chainHead?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, SerialNumber?: { Id?: number, LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/get_carton_info_by_taskId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
