<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="clear_button" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="handle_reset($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Label of newGroup1</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-job_selector" *ngIf="!filters.job_selector.hidden" 
                                  class="field-container standard {{filters.job_selector.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.job_selector.styles.style"
                                  [ngClass]="filters.job_selector.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.job_selector.label + (filters.job_selector.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.job_selector.label}}<span *ngIf="filters.job_selector.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <LocusRobotics-jobs_selector_single 
                                  data-cy="selector"
                                  [type]="filters.job_selector.control.type"
                                  formControlName="job_selector"
                                  (displayTextChange)="filters.job_selector.control.displayText=$event"
                                  [placeholder]="filters.job_selector.control.placeholder"
                                  [styles]="filters.job_selector.control.styles"
                                  [tooltip]="filters.job_selector.control.tooltip"
                              >
                              </LocusRobotics-jobs_selector_single>
                              <ng-container *ngIf="filters.job_selector.invalid">
                                <ng-container *ngFor="let error of filters.job_selector.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-cart_or_job_id" *ngIf="!filters.cart_or_job_id.hidden" 
                                  class="field-container double {{filters.cart_or_job_id.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.cart_or_job_id.styles.style"
                                  [ngClass]="filters.cart_or_job_id.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.cart_or_job_id.label + (filters.cart_or_job_id.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.cart_or_job_id.label}}<span *ngIf="filters.cart_or_job_id.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="cart_or_job_id"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.cart_or_job_id.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.cart_or_job_id.control.placeholder}}"
                                      [ngStyle]="filters.cart_or_job_id.control.styles.style"
                                      [ngClass]="filters.cart_or_job_id.control.styles.classes"
                                      [matTooltip]="filters.cart_or_job_id.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.cart_or_job_id.invalid">
                                <ng-container *ngFor="let error of filters.cart_or_job_id.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-pallet_location" *ngIf="!filters.pallet_location.hidden" 
                                  class="field-container full {{filters.pallet_location.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.pallet_location.styles.style"
                                  [ngClass]="filters.pallet_location.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.pallet_location.label + (filters.pallet_location.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.pallet_location.label}}<span *ngIf="filters.pallet_location.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="pallet_location"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.pallet_location.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.pallet_location.control.placeholder}}"
                                      [ngStyle]="filters.pallet_location.control.styles.style"
                                      [ngClass]="filters.pallet_location.control.styles.classes"
                                      [matTooltip]="filters.pallet_location.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.pallet_location.invalid">
                                <ng-container *ngFor="let error of filters.pallet_location.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!filtersets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup2.collapsible }">
                    <div *ngIf="!filtersets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Label of newGroup2</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup2.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-scanned_values" *ngIf="!filters.scanned_values.hidden" 
                                  class="field-container double {{filters.scanned_values.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.scanned_values.styles.style"
                                  [ngClass]="filters.scanned_values.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.scanned_values.label + (filters.scanned_values.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.scanned_values.label}}<span *ngIf="filters.scanned_values.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="scanned_values"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.scanned_values.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.scanned_values.control.placeholder}}"
                                      [ngStyle]="filters.scanned_values.control.styles.style"
                                      [ngClass]="filters.scanned_values.control.styles.classes"
                                      [matTooltip]="filters.scanned_values.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.scanned_values.invalid">
                                <ng-container *ngFor="let error of filters.scanned_values.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.current_pallet.hidden" class="widget-container">
            <LocusRobotics-current_job_widget
            #$widgets_current_pallet 
              [jobId]="$widgets_current_pallet_inParams_jobId"
            ($refreshEvent)="refresh(false, false, '$widgets_current_pallet')"
            >
            </LocusRobotics-current_job_widget>
          </div>
          <div *ngIf="!widgets.total_jobs.hidden" class="widget-container">
            <LocusRobotics-total_pallet_widget
            #$widgets_total_jobs 
              [jobId]="$widgets_total_jobs_inParams_jobId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_jobs')"
            >
            </LocusRobotics-total_pallet_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.job_contents.hidden" class="tab" data-cy="tab-job_contents">
              <h2 [className]="tabs.job_contents.active? 'active': ''" (click)="tabs.job_contents.activate()">{{tabs.job_contents.title}}</h2>
            </div>
            <div *ngIf="!tabs.scanned_values.hidden" class="tab" data-cy="tab-scanned_values">
              <h2 [className]="tabs.scanned_values.active? 'active': ''" (click)="tabs.scanned_values.activate()">{{tabs.scanned_values.title}}</h2>
            </div>
          </div>
        
              <LocusRobotics-pallet_build_grid *ngIf="tabs.job_contents.active"
              #$tabs_job_contents
              [jobId]="$tabs_job_contents_pallet_build_grid_inParams_jobId"
              [readyforbuild]="$tabs_job_contents_pallet_build_grid_inParams_readyforbuild"
              (reset)="handle_reset($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_job_contents')">
              </LocusRobotics-pallet_build_grid>
              <LocusRobotics-scanned_values_grid *ngIf="tabs.scanned_values.active"
              #$tabs_scanned_values
              [scannedValues]="$tabs_scanned_values_scanned_values_grid_inParams_scannedValues"
              [expectedValues]="$tabs_scanned_values_scanned_values_grid_inParams_expectedValues"
              ($refreshEvent)="refresh(false, false, '$tabs_scanned_values')">
              </LocusRobotics-scanned_values_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>