import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { Invoices_billing_records_total_invoiced_by_date_widgetComponent } from './Invoices.billing_records_total_invoiced_by_date_widget.component';
import { Invoices_billing_record_total_billed_by_date_widgetComponent } from './Invoices.billing_record_total_billed_by_date_widget.component';
import { Invoices_billing_record_total_billed_by_current_month_widgetComponent } from './Invoices.billing_record_total_billed_by_current_month_widget.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_materials_by_project_dd_multiComponent } from './Materials.materials_by_project_dd_multi.component'
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_billing_records_gridComponent),
    forwardRef(() => Invoices_billing_records_total_invoiced_by_date_widgetComponent),
    forwardRef(() => Invoices_billing_record_total_billed_by_date_widgetComponent),
    forwardRef(() => Invoices_billing_record_total_billed_by_current_month_widgetComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_multiComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
  ],
  selector: 'FootPrintManager-billing_records_hub',
  templateUrl: './FootPrintManager.billing_records_hub.component.html'
})
export class FootPrintManager_billing_records_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentDate?: string, current_date_corrected?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    materials: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    include_cancelled: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    include_invoiced: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', false),
    materials: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['materials'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Materials', false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouses', false),
    include_cancelled: new FieldModel(new CheckBoxModel(this.formGroup.controls['include_cancelled'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Include cancelled', false),
    include_invoiced: new FieldModel(new CheckBoxModel(this.formGroup.controls['include_invoiced'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Include invoiced', false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      billing_records: new TabItemModel(
        this.rootTabGroup, 
        'Billing records', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_billing_records_billing_records_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_billing_records_billing_records_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_records_billing_records_grid_inParams_materialIds: number[];
    get $tabs_billing_records_billing_records_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_records_billing_records_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_billing_records_billing_records_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_records_billing_records_grid_inParams_materialIds;
    }
  
    cacheValueFor_$tabs_billing_records_billing_records_grid_inParams_warehouseIds: number[];
    get $tabs_billing_records_billing_records_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_records_billing_records_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_billing_records_billing_records_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_records_billing_records_grid_inParams_warehouseIds;
    }
  
    get $tabs_billing_records_billing_records_grid_inParams_includeCancelled(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.include_cancelled.control.value;
      
      return expr;
    }
  
    get $tabs_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.include_invoiced.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_billing_records', { read: FootPrintManager_billing_records_gridComponent }) $tabs_billing_records: FootPrintManager_billing_records_gridComponent;
    //#endregion tabs children
    widgets = {
      billing_records_total_invoiced_by_date_widget: new WidgetModel(),
      billing_records_total_billed_by_date_widget: new WidgetModel(),
      billing_record_total_billed_by_current_month: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_billing_records_total_invoiced_by_date_widget_inParams_date(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_billing_records_total_invoiced_by_date_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_billing_records_total_invoiced_by_date_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_billing_records_total_invoiced_by_date_widget_inParams_warehouseIds: number[];
    get $widgets_billing_records_total_invoiced_by_date_widget_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_billing_records_total_invoiced_by_date_widget_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$widgets_billing_records_total_invoiced_by_date_widget_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$widgets_billing_records_total_invoiced_by_date_widget_inParams_warehouseIds;
    }
  
    cacheValueFor_$widgets_billing_records_total_invoiced_by_date_widget_inParams_materialIds: number[];
    get $widgets_billing_records_total_invoiced_by_date_widget_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_billing_records_total_invoiced_by_date_widget_inParams_materialIds, expr)) {
        this.cacheValueFor_$widgets_billing_records_total_invoiced_by_date_widget_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$widgets_billing_records_total_invoiced_by_date_widget_inParams_materialIds;
    }
  
    get $widgets_billing_records_total_billed_by_date_widget_inParams_date(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_billing_records_total_billed_by_date_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_billing_records_total_billed_by_date_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_billing_records_total_billed_by_date_widget_inParams_warehouseIds: number[];
    get $widgets_billing_records_total_billed_by_date_widget_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_billing_records_total_billed_by_date_widget_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$widgets_billing_records_total_billed_by_date_widget_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$widgets_billing_records_total_billed_by_date_widget_inParams_warehouseIds;
    }
  
    cacheValueFor_$widgets_billing_records_total_billed_by_date_widget_inParams_materialIds: number[];
    get $widgets_billing_records_total_billed_by_date_widget_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_billing_records_total_billed_by_date_widget_inParams_materialIds, expr)) {
        this.cacheValueFor_$widgets_billing_records_total_billed_by_date_widget_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$widgets_billing_records_total_billed_by_date_widget_inParams_materialIds;
    }
  
    get $widgets_billing_record_total_billed_by_current_month_inParams_date(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_billing_record_total_billed_by_current_month_inParams_owner_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_billing_record_total_billed_by_current_month_inParams_project_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_billing_record_total_billed_by_current_month_inParams_warehouse_ids: number[];
    get $widgets_billing_record_total_billed_by_current_month_inParams_warehouse_ids(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_billing_record_total_billed_by_current_month_inParams_warehouse_ids, expr)) {
        this.cacheValueFor_$widgets_billing_record_total_billed_by_current_month_inParams_warehouse_ids = expr;
      }
      return this.cacheValueFor_$widgets_billing_record_total_billed_by_current_month_inParams_warehouse_ids;
    }
  
    cacheValueFor_$widgets_billing_record_total_billed_by_current_month_inParams_material_ids: number[];
    get $widgets_billing_record_total_billed_by_current_month_inParams_material_ids(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_billing_record_total_billed_by_current_month_inParams_material_ids, expr)) {
        this.cacheValueFor_$widgets_billing_record_total_billed_by_current_month_inParams_material_ids = expr;
      }
      return this.cacheValueFor_$widgets_billing_record_total_billed_by_current_month_inParams_material_ids;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_billing_records_total_invoiced_by_date_widget', { read:  Invoices_billing_records_total_invoiced_by_date_widgetComponent }) $widgets_billing_records_total_invoiced_by_date_widget: Invoices_billing_records_total_invoiced_by_date_widgetComponent;
      @ViewChild('$widgets_billing_records_total_billed_by_date_widget', { read:  Invoices_billing_record_total_billed_by_date_widgetComponent }) $widgets_billing_records_total_billed_by_date_widget: Invoices_billing_record_total_billed_by_date_widgetComponent;
      @ViewChild('$widgets_billing_record_total_billed_by_current_month', { read:  Invoices_billing_record_total_billed_by_current_month_widgetComponent }) $widgets_billing_record_total_billed_by_current_month: Invoices_billing_record_total_billed_by_current_month_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  get $fields_materials_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Billing records hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view billing activity`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.billing_records,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Billing Records Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.include_cancelled.control as CheckBoxModel).reset(false);
    (this.filters.include_invoiced.control as CheckBoxModel).reset(false);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_billing_records_total_invoiced_by_date_widget') {
      if (!isNil(this.$widgets_billing_records_total_invoiced_by_date_widget) && !this.widgets.billing_records_total_invoiced_by_date_widget.hidden) {
        this.$widgets_billing_records_total_invoiced_by_date_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_billing_records_total_billed_by_date_widget') {
      if (!isNil(this.$widgets_billing_records_total_billed_by_date_widget) && !this.widgets.billing_records_total_billed_by_date_widget.hidden) {
        this.$widgets_billing_records_total_billed_by_date_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_billing_record_total_billed_by_current_month') {
      if (!isNil(this.$widgets_billing_record_total_billed_by_current_month) && !this.widgets.billing_record_total_billed_by_current_month.hidden) {
        this.$widgets_billing_record_total_billed_by_current_month.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_billing_records') {
      if (!isNil(this.$tabs_billing_records) && !this.tabs.billing_records.hidden) {
        this.$tabs_billing_records.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_billing_records_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'billing_records_hub.on_init');
  // Set the title
  $hub.title = 'Billing Records Hub'
  
  $hub.vars.currentDate = $utils.date.now();
  
  
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: FootPrintManager_billing_records_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'billing_records_hub.on_owner_change');
  $hub.filters.project.control.value = null;
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: FootPrintManager_billing_records_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'billing_records_hub.on_project_change');
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  //#endregion private flows
}
