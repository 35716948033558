import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_material_packagings_gridComponent } from './FootPrintManager.material_packagings_grid.component';
import { FootPrintManager_materials_udf_gridComponent } from './FootPrintManager.materials_udf_grid.component';
import { FootPrintManager_material_catalog_source_material_project_subscribers_gridComponent } from './FootPrintManager.material_catalog_source_material_project_subscribers_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_material_packagings_gridComponent),
    forwardRef(() => FootPrintManager_materials_udf_gridComponent),
    forwardRef(() => FootPrintManager_material_catalog_source_material_project_subscribers_gridComponent),
  ],
  selector: 'FootPrintManager-single_material_catalog_source_material_hub',
  templateUrl: './FootPrintManager.single_material_catalog_source_material_hub.component.html'
})
export class FootPrintManager_single_material_catalog_source_material_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { materialId: number } = { materialId: null };
  //#region Inputs
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string, project_id?: number } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, 'Edit', 'icon-ic_fluent_edit_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    ),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_print_20_regular', null)
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    )
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      packagings: new TabItemModel(
        this.rootTabGroup, 
        'Packagings', 
        ),
      udfs: new TabItemModel(
        this.rootTabGroup, 
        'Custom fields', 
        ),
      project_subscribers: new TabItemModel(
        this.rootTabGroup, 
        'Project subscribers', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_packagings_material_packagings_grid_inParams_materialId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_udfs_materials_udf_grid_inParams_materialIds: number[];
    get $tabs_udfs_materials_udf_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.materialId];
      
      if(!isEqual(this.cacheValueFor_$tabs_udfs_materials_udf_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_udfs_materials_udf_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_udfs_materials_udf_grid_inParams_materialIds;
    }
  
    get $tabs_project_subscribers_material_catalog_source_material_project_subscribers_grid_inParams_source_material_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_packagings', { read: FootPrintManager_material_packagings_gridComponent }) $tabs_packagings: FootPrintManager_material_packagings_gridComponent;
      @ViewChild('$tabs_udfs', { read: FootPrintManager_materials_udf_gridComponent }) $tabs_udfs: FootPrintManager_materials_udf_gridComponent;
      @ViewChild('$tabs_project_subscribers', { read: FootPrintManager_material_catalog_source_material_project_subscribers_gridComponent }) $tabs_project_subscribers: FootPrintManager_material_catalog_source_material_project_subscribers_gridComponent;
    //#endregion tabs children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.packagings,
      this.tabs.udfs,
      this.tabs.project_subscribers,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single material catalog source material Hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_packagings') {
      if (!isNil(this.$tabs_packagings) && !this.tabs.packagings.hidden) {
        this.$tabs_packagings.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_udfs') {
      if (!isNil(this.$tabs_udfs) && !this.tabs.udfs.hidden) {
        this.$tabs_udfs.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_project_subscribers') {
      if (!isNil(this.$tabs_project_subscribers) && !this.tabs.project_subscribers.hidden) {
        this.$tabs_project_subscribers.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_material_catalog_source_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $hub.inParams.materialId })).result;
  if ($utils.isDefined(material)) {
  
      $hub.vars.project_id = material[0].ProjectId;
  
      $hub.title = `Material ${material[0].LookupCode}`;
  
      const title = `Material ${material[0].LookupCode} Status ${material[0].Status.Name} `;
  
      if ($utils.isDefined(material[0].Description)) {
          const description = `${material[0].Description}  `;
          $hub.vars.hubDescription = description;
      }
  
      $hub.vars.hubTitle = title;
  
  }
  
  
  
  
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_material_catalog_source_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = (await $shell.FootPrintManager.openmaterial_editorDialog({materialId: $hub.inParams.materialId},'flyout'));
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
  
      await $hub.on_init();
      await $hub.refresh();
  }
  
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $hub: FootPrintManager_single_material_catalog_source_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.Catalogs.is_material_catalog_source_material_deletable_flow({ material_ids: [$hub.inParams.materialId] });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Source material cannot be deleted.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete source material', `Are you sure you want to delete the material?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.Materials.delete_material_flow({ materialId: $hub.inParams.materialId });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Source material cannot be deleted.', reason);
                  return;
              }
              else {
                  $hub.close();
              }
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Material delete error', 'An error occurred during the deletion of the material', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $hub: FootPrintManager_single_material_catalog_source_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.openInfoDialog('Not yet implemented', 'The print functionality for this screen has not yet been implemented.');
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $hub: FootPrintManager_single_material_catalog_source_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openmaterial_attachments_hubDialog({ materialId: $hub.inParams.materialId });
  }
  //#endregion private flows
}
