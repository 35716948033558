import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { DockAppointments_ShellService } from './DockAppointments.shell.service';
import { DockAppointments_OperationService } from './DockAppointments.operation.service';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { DockAppointments_LocalizationService } from './DockAppointments.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './DockAppointments.frontend.types'
import { $frontendTypes as $types} from './DockAppointments.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'DockAppointments-wait_time_widget',
  templateUrl: './DockAppointments.wait_time_widget.component.html'
})
export class DockAppointments_wait_time_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { waitTime: number } = { waitTime: null };
  //#region Inputs
  @Input('waitTime') set $inParams_waitTime(v: number) {
    this.inParams.waitTime = v;
  }
  get $inParams_waitTime(): number {
    return this.inParams.waitTime;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: DockAppointments_ShellService,
private datasources: DockAppointments_DatasourceService,
private flows: DockAppointments_FlowService,
private reports: DockAppointments_ReportService,
private localization: DockAppointments_LocalizationService,
private operations: DockAppointments_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.suffix = 'Min';

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.waitTime)) {
        this.$missingRequiredInParams.push('waitTime');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    await this.on_init();

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

      this.$hasDataLoaded = true;
      await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = $widget.inParams.waitTime;

    
    this.originalValue = $widget.inParams.waitTime;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $widget: DockAppointments_wait_time_widgetComponent,
  
    $shell: DockAppointments_ShellService,
    $datasources: DockAppointments_DatasourceService,
    $flows: DockAppointments_FlowService,
    $reports: DockAppointments_ReportService,
    $settings: SettingsValuesService,
    $operations: DockAppointments_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: DockAppointments_LocalizationService,
    $event: any
  ) {
    this.logger.log('DockAppointments', 'wait_time_widget.on_init');
  
  if ($utils.isDefined($widget.inParams.waitTime)) {
      if ($widget.inParams.waitTime > 120) {
          $widget.styles.setBadClass();
      }
      else {
          $widget.styles.setGoodClass();
      }
  }
  else {
      $widget.styles.setGoodClass();
  }
  }
  //#endregion private flows
}
