import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Locations_create_location_fnService } from './Locations.flow.index';
import { Locations_delete_location_project_flowService } from './Locations.flow.index';

import { $frontendTypes } from './Locations.frontend.types'

@Injectable({ providedIn: 'root' })
export class Locations_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Locations: Locations_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_location_fn: Locations_create_location_fnService;
  public async create_location_fn(inParams: { location: any }): Promise< { reasons?: string[], locationId?: number }> {
    if(!this._create_location_fn) {
      this._create_location_fn = this.injector.get(Locations_create_location_fnService);
    }
    return this._create_location_fn.run(inParams);
  }
   
   

   
 
  private _delete_location_project_flow: Locations_delete_location_project_flowService;
  public async delete_location_project_flow(inParams: { locationId: number, projectId: number }): Promise< { reason?: string }> {
    if(!this._delete_location_project_flow) {
      this._delete_location_project_flow = this.injector.get(Locations_delete_location_project_flowService);
    }
    return this._delete_location_project_flow.run(inParams);
  }
   
   

   
}
