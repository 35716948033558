import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_dock_appointments_total_pallets_widget',
  templateUrl: './app.custom_dock_appointments_total_pallets_widget.component.html'
})
export class app_custom_dock_appointments_total_pallets_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { statusIds?: number[], carrierId?: number, typeIds?: number[], ownerId?: number, projectId?: number, locationIds?: number[], fromDate?: string, toDate?: string, fullTextSearch?: string, warehouseId?: number[] } = { statusIds: [], carrierId: null, typeIds: [], ownerId: null, projectId: null, locationIds: [], fromDate: null, toDate: null, fullTextSearch: null, warehouseId: [] };
  //#region Inputs
  @Input('statusIds') set $inParams_statusIds(v: number[]) {
    this.inParams.statusIds = v;
  }
  get $inParams_statusIds(): number[] {
    return this.inParams.statusIds;
  }
  @Input('carrierId') set $inParams_carrierId(v: number) {
    this.inParams.carrierId = v;
  }
  get $inParams_carrierId(): number {
    return this.inParams.carrierId;
  }
  @Input('typeIds') set $inParams_typeIds(v: number[]) {
    this.inParams.typeIds = v;
  }
  get $inParams_typeIds(): number[] {
    return this.inParams.typeIds;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('locationIds') set $inParams_locationIds(v: number[]) {
    this.inParams.locationIds = v;
  }
  get $inParams_locationIds(): number[] {
    return this.inParams.locationIds;
  }
  @Input('fromDate') set $inParams_fromDate(v: string) {
    this.inParams.fromDate = v;
  }
  get $inParams_fromDate(): string {
    return this.inParams.fromDate;
  }
  @Input('toDate') set $inParams_toDate(v: string) {
    this.inParams.toDate = v;
  }
  get $inParams_toDate(): string {
    return this.inParams.toDate;
  }
  @Input('fullTextSearch') set $inParams_fullTextSearch(v: string) {
    this.inParams.fullTextSearch = v;
  }
  get $inParams_fullTextSearch(): string {
    return this.inParams.fullTextSearch;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number[]) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number[] {
    return this.inParams.warehouseId;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  
  private entity: { TotalPallets?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      locationIds:  $widget.inParams.locationIds ,
      fromDate:  $widget.inParams.fromDate ,
      typeIds:  $widget.inParams.typeIds ,
      ownerId:  $widget.inParams.ownerId ,
      projectId:  $widget.inParams.projectId ,
      carrierId:  $widget.inParams.carrierId ,
      statusIds:  $widget.inParams.statusIds ,
      toDate:  $widget.inParams.toDate ,
      fullTextSearch:  $widget.inParams.fullTextSearch ,
      warehouseId:  $widget.inParams.warehouseId 
    };

    const data = await this.datasources.app.custom_ds_dock_appointments_total_pallets_widget.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = this.format($utils.isDefined($widget.entity.TotalPallets) ? $widget.entity.TotalPallets : 0, 1);

    
    this.originalValue = $utils.isDefined($widget.entity.TotalPallets) ? $widget.entity.TotalPallets : 0;
  }

  format(num: number, digits: number): string {
    // at some point in time we should probably respect the selected culture from the localization service
    return isNil(num)
      ? ''
      : Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: digits}).format(num);
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

}
