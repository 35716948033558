import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_license_plate_report_by_serialIdServiceInParams {
  serialNumberId?: number}

interface IReports_custom_license_plate_report_by_serialIdServiceData {
  LicensePlate?: { result?: { Id?: number, LookupCode?: string, LicensePlate?: { Id?: number, LookupCode?: string } }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_license_plate_report_by_serialIdService extends ReportBaseService<IReports_custom_license_plate_report_by_serialIdServiceInParams, IReports_custom_license_plate_report_by_serialIdServiceData> {

  protected reportReferenceName = 'custom_license_plate_report_by_serialId';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('Reports', 'custom_license_plate_report_by_serialId');
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_license_plate_report_by_serialIdServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_license_plate_report_by_serialIdServiceInParams): Promise<IReports_custom_license_plate_report_by_serialIdServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_license_plate_report_by_serialIdServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_license_plate_report_by_serialIdServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        serialNumberId:  $report.inParams.serialNumberId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_licenseplate_details_by_serialId.get(dsParams);
      
      data.LicensePlate = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
