<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.jobs_info.hidden" class="tab" data-cy="tab-jobs_info">
              <h2 [className]="tabs.jobs_info.active? 'active': ''" (click)="tabs.jobs_info.activate()">{{tabs.jobs_info.title}}</h2>
            </div>
          </div>
        
              <LocusRobotics-jobs_detail_by_order_grid *ngIf="tabs.jobs_info.active"
              #$tabs_jobs_info
              [orderId]="$tabs_jobs_info_jobs_detail_by_order_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_jobs_info')">
              </LocusRobotics-jobs_detail_by_order_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>