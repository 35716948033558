import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_sales_order_lines_gridComponent } from './FootPrintManager.sales_order_lines_grid.component';
import { FootPrintManager_sales_order_picking_gridComponent } from './FootPrintManager.sales_order_picking_grid.component';
import { FootPrintManager_manual_allocations_gridComponent } from './FootPrintManager.manual_allocations_grid.component';
import { app_custom_shippingcontents_gridComponent } from './app.custom_shippingcontents_grid.component';
import { SalesOrders_outbound_bill_of_lading_reportComponent } from './SalesOrders.outbound_bill_of_lading_report.component';
import { SalesOrders_pick_slip_by_orderId_reportComponent } from './SalesOrders.pick_slip_by_orderId_report.component';
import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { FootPrintManager_orderaddresses_gridComponent } from './FootPrintManager.orderaddresses_grid.component';
import { FootPrintManager_instructions_gridComponent } from './FootPrintManager.instructions_grid.component';
import { FootPrintManager_sales_order_tasks_gridComponent } from './FootPrintManager.sales_order_tasks_grid.component';
import { SalesOrders_order_total_units_widgetComponent } from './SalesOrders.order_total_units_widget.component';
import { SalesOrders_order_total_picked_units_widgetComponent } from './SalesOrders.order_total_picked_units_widget.component';
import { SalesOrders_order_total_picked_gross_widgetComponent } from './SalesOrders.order_total_picked_gross_widget.component';
import { SalesOrders_orderclasses_dd_singleComponent } from './SalesOrders.orderclasses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Carriers_carriers_ownerscarriers_dd_singleComponent } from './Carriers.carriers_ownerscarriers_dd_single.component'
import { Carriers_carrierservicetype_dd_singleComponent } from './Carriers.carrierservicetype_dd_single.component'
import { SalesOrders_container_sizes_dd_singleComponent } from './SalesOrders.container_sizes_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_sales_order_lines_gridComponent),
    forwardRef(() => FootPrintManager_sales_order_picking_gridComponent),
    forwardRef(() => FootPrintManager_manual_allocations_gridComponent),
    forwardRef(() => app_custom_shippingcontents_gridComponent),
    forwardRef(() => SalesOrders_outbound_bill_of_lading_reportComponent),
    forwardRef(() => SalesOrders_pick_slip_by_orderId_reportComponent),
    forwardRef(() => FootPrintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootPrintManager_billing_records_gridComponent),
    forwardRef(() => FootPrintManager_orderaddresses_gridComponent),
    forwardRef(() => FootPrintManager_instructions_gridComponent),
    forwardRef(() => FootPrintManager_sales_order_tasks_gridComponent),
    forwardRef(() => SalesOrders_order_total_units_widgetComponent),
    forwardRef(() => SalesOrders_order_total_picked_units_widgetComponent),
    forwardRef(() => SalesOrders_order_total_picked_gross_widgetComponent),
    forwardRef(() => SalesOrders_orderclasses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Carriers_carriers_ownerscarriers_dd_singleComponent),
    forwardRef(() => Carriers_carrierservicetype_dd_singleComponent),
    forwardRef(() => SalesOrders_container_sizes_dd_singleComponent),
  ],
  selector: 'app-custom_sales_order_editor',
  templateUrl: './app.custom_sales_order_editor.component.html'
})
export class app_custom_sales_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { orderId: number } = { orderId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipmentId?: number, billingAddressId?: number, orderState?: number } = { };
  //#endregion
  entity: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, ParentOrderId?: number, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { LookupCode?: string, Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, CarrierId?: number, CarrierServiceTypeId?: number, ContainerIdentifier?: string, ContainerSize?: string } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, container_size?: { Id?: number, Name?: string } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier_service: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    container_size: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, 'Process', 'ms-Icon ms-Icon--Process', null)
    ),
      auto_pick: new ToolModel(new ButtonModel('auto_pick', new ButtonStyles(null, null), false, 'Auto Pick', 'ms-Icon ms-Icon--AutomateFlow', null)
    ),
      auto_load: new ToolModel(new ButtonModel('auto_load', new ButtonStyles(null, null), false, 'Auto Load', 'ms-Icon ms-Icon--BusinessRule', null)
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'ms-Icon ms-Icon--AcceptMedium', null)
    ),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, 'Appointment', 'ms-Icon ms-Icon--Calendar', null)
    ),
      transfer_outstanding: new ToolModel(new ButtonModel('transfer_outstanding', new ButtonStyles(null, null), false, 'Transfer outstanding', 'icon-ic_fluent_arrow_bidirectional_left_right_20_regular', null)
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel', 'ms-Icon ms-Icon--Blocked', null)
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete', null)
    ),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Print', null)
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Attach', null)
    ),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Questionnaire', null)
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Lookupcode', true),
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  true, 
  '', null)
, new ControlContainerStyles(null, null), 'Order Class', true),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', true),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true),
    order_status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Order Status', false),
    order_state: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Order state', false),
    parent_order: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, 'Select parent order', '', null)
, new ControlContainerStyles(null, null), 'Parent order', false),
    clear_parent_order: new FieldModel(new ButtonModel('', new ButtonStyles(['destructive'], null), false, 'Clear', 'ms-Icon ms-Icon--Blocked', null)
, new ControlContainerStyles(null, null), ' ', false),
    ship_to_selection: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Ship To', 'ms-Icon ms-Icon--AccountBrowser', null)
, new ControlContainerStyles(null, null), '', false),
    ship_to_clear: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Clear', 'ms-Icon ms-Icon--Blocked', null)
, new ControlContainerStyles(null, null), '', false),
    ship_to: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false),
    owner_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['owner_reference'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'PO', false),
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Planned Ship Date', false),
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Carrier', false),
    carrier_service: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier_service'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Service', false),
    vendor_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_reference'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Reference', false),
    container_size: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['container_size'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Container Size', false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false),
    custom_fields: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Custom fields', 'ms-Icon ms-Icon--Edit', null)
, new ControlContainerStyles(null, null), '', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      reports: new TabGroupModel(),
      billing: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      sales_order_lines: new TabItemModel(
        this.rootTabGroup, 
        'Lines', 
        ),
      picking_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Pick Tasks', 
        ),
      manual_allocation_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Manual Tasks', 
        ),
      shipping_contents: new TabItemModel(
        this.rootTabGroup, 
        'Picked', 
        ),
      reports: new TabItemModel(
        this.rootTabGroup, 
        'Reports', 
        true,
        {
          bol_report: new TabItemModel(
          this.subTabGroups.reports, 
          'Bill of Lading', 
          ),
          pick_slip_report: new TabItemModel(
          this.subTabGroups.reports, 
          'Pick Slip', 
          ),
        }
        ),
      billing: new TabItemModel(
        this.rootTabGroup, 
        'Billing', 
        true,
        {
          accessorial_tasks: new TabItemModel(
          this.subTabGroups.billing, 
          'Accessorials', 
          ),
          billing_records: new TabItemModel(
          this.subTabGroups.billing, 
          'Billing Records', 
          ),
        }
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        ),
      instructions: new TabItemModel(
        this.rootTabGroup, 
        'Instructions', 
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    get $tabs_picking_tasks_sales_order_picking_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_picking_tasks_sales_order_picking_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_picking_tasks_sales_order_picking_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_manual_allocation_tasks_manual_allocations_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_shipping_contents_custom_shippingcontents_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_shipping_contents_custom_shippingcontents_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.shipmentId;
      
      return expr;
    }
  
    get $tabs_reports_bol_report_outbound_bill_of_lading_report_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_reports_pick_slip_report_pick_slip_by_orderId_report_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_showAllOpCodes(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_orderIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.inParams.orderId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds;
    }
  
    get $tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.ShipmentOrderLookups[0]?.ShipmentId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds;
    }
  
    get $tabs_contacts_orderaddresses_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityName(): string {
      const $editor = this;
      const $utils = this.utils;
      const expr = 'Order';
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_enabled(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityKey1(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_tasks_sales_order_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_tasks_sales_order_tasks_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_sales_order_lines', { read: FootPrintManager_sales_order_lines_gridComponent }) $tabs_sales_order_lines: FootPrintManager_sales_order_lines_gridComponent;
      @ViewChild('$tabs_picking_tasks', { read: FootPrintManager_sales_order_picking_gridComponent }) $tabs_picking_tasks: FootPrintManager_sales_order_picking_gridComponent;
      @ViewChild('$tabs_manual_allocation_tasks', { read: FootPrintManager_manual_allocations_gridComponent }) $tabs_manual_allocation_tasks: FootPrintManager_manual_allocations_gridComponent;
      @ViewChild('$tabs_shipping_contents', { read: app_custom_shippingcontents_gridComponent }) $tabs_shipping_contents: app_custom_shippingcontents_gridComponent;
        @ViewChild('$tabs_reports_bol_report', { read: SalesOrders_outbound_bill_of_lading_reportComponent }) $tabs_reports_bol_report: SalesOrders_outbound_bill_of_lading_reportComponent;
        @ViewChild('$tabs_reports_pick_slip_report', { read: SalesOrders_pick_slip_by_orderId_reportComponent }) $tabs_reports_pick_slip_report: SalesOrders_pick_slip_by_orderId_reportComponent;
        @ViewChild('$tabs_billing_accessorial_tasks', { read: FootPrintManager_accessorial_tasks_gridComponent }) $tabs_billing_accessorial_tasks: FootPrintManager_accessorial_tasks_gridComponent;
        @ViewChild('$tabs_billing_billing_records', { read: FootPrintManager_billing_records_gridComponent }) $tabs_billing_billing_records: FootPrintManager_billing_records_gridComponent;
      @ViewChild('$tabs_contacts', { read: FootPrintManager_orderaddresses_gridComponent }) $tabs_contacts: FootPrintManager_orderaddresses_gridComponent;
      @ViewChild('$tabs_instructions', { read: FootPrintManager_instructions_gridComponent }) $tabs_instructions: FootPrintManager_instructions_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_sales_order_tasks_gridComponent }) $tabs_tasks: FootPrintManager_sales_order_tasks_gridComponent;
    //#endregion tabs children
    widgets = {
      order_total_units_widget: new WidgetModel(),
      order_total_picked_units_widget: new WidgetModel(),
      order_total_picked_gross_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_order_total_units_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_widget_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.shipmentId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_gross_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_gross_widget_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.shipmentId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_order_total_units_widget', { read:  SalesOrders_order_total_units_widgetComponent }) $widgets_order_total_units_widget: SalesOrders_order_total_units_widgetComponent;
      @ViewChild('$widgets_order_total_picked_units_widget', { read:  SalesOrders_order_total_picked_units_widgetComponent }) $widgets_order_total_picked_units_widget: SalesOrders_order_total_picked_units_widgetComponent;
      @ViewChild('$widgets_order_total_picked_gross_widget', { read:  SalesOrders_order_total_picked_gross_widgetComponent }) $widgets_order_total_picked_gross_widget: SalesOrders_order_total_picked_gross_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  get $fields_carrier_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.Project.OwnerId;
    
    return expr;
  }

  get $fields_carrier_service_selector_inParams_carrierId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.carrier.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    owner_reference: this.fields.owner_reference.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    carrier_service: this.fields.carrier_service.control.valueChanges
    ,
    vendor_reference: this.fields.vendor_reference.control.valueChanges
    ,
    container_size: this.fields.container_size.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.sales_order_lines,
      this.tabs.picking_tasks,
      this.tabs.manual_allocation_tasks,
      this.tabs.shipping_contents,
      this.tabs.reports,
      this.tabs.billing,
      this.tabs.contacts,
      this.tabs.instructions,
      this.tabs.tasks,
    ]; 
    this.subTabGroups.reports.tabs = [
        this.tabs.reports.tabs.bol_report,
        this.tabs.reports.tabs.pick_slip_report,
    ];    
    this.subTabGroups.billing.tabs = [
        this.tabs.billing.tabs.accessorial_tasks,
        this.tabs.billing.tabs.billing_records,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Sales Order';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.orderId 
    };

    const data = await this.datasources.app.custom_ds_sales_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClassId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.PreferredWarehouseId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.order_state.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.owner_reference.control as TextBoxModel).reset($editor.entity.OwnerReference);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.RequestedDeliveryDate);
    (this.fields.carrier.control as SelectBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.CarrierId);
    (this.fields.carrier_service.control as SelectBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.CarrierServiceTypeId);
    (this.fields.vendor_reference.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.container_size.control as SelectBoxModel).reset($editor.entity.container_size?.Id);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_order_total_units_widget') {
      if (!isNil(this.$widgets_order_total_units_widget) && !this.widgets.order_total_units_widget.hidden) {
        this.$widgets_order_total_units_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_picked_units_widget') {
      if (!isNil(this.$widgets_order_total_picked_units_widget) && !this.widgets.order_total_picked_units_widget.hidden) {
        this.$widgets_order_total_picked_units_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_picked_gross_widget') {
      if (!isNil(this.$widgets_order_total_picked_gross_widget) && !this.widgets.order_total_picked_gross_widget.hidden) {
        this.$widgets_order_total_picked_gross_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_sales_order_lines') {
        if (!isNil(this.$tabs_sales_order_lines) && !this.tabs.sales_order_lines.hidden) {
          this.$tabs_sales_order_lines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_picking_tasks') {
        if (!isNil(this.$tabs_picking_tasks) && !this.tabs.picking_tasks.hidden) {
          this.$tabs_picking_tasks.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_manual_allocation_tasks') {
        if (!isNil(this.$tabs_manual_allocation_tasks) && !this.tabs.manual_allocation_tasks.hidden) {
          this.$tabs_manual_allocation_tasks.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_shipping_contents') {
        if (!isNil(this.$tabs_shipping_contents) && !this.tabs.shipping_contents.hidden) {
          this.$tabs_shipping_contents.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_reports_bol_report') {
        if (!isNil(this.$tabs_reports_bol_report) && !this.tabs.reports.tabs.bol_report.hidden) {
          this.$tabs_reports_bol_report.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_reports_pick_slip_report') {
        if (!isNil(this.$tabs_reports_pick_slip_report) && !this.tabs.reports.tabs.pick_slip_report.hidden) {
          this.$tabs_reports_pick_slip_report.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_accessorial_tasks') {
        if (!isNil(this.$tabs_billing_accessorial_tasks) && !this.tabs.billing.tabs.accessorial_tasks.hidden) {
          this.$tabs_billing_accessorial_tasks.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_billing_records') {
        if (!isNil(this.$tabs_billing_billing_records) && !this.tabs.billing.tabs.billing_records.hidden) {
          this.$tabs_billing_billing_records.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_contacts') {
        if (!isNil(this.$tabs_contacts) && !this.tabs.contacts.hidden) {
          this.$tabs_contacts.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_instructions') {
        if (!isNil(this.$tabs_instructions) && !this.tabs.instructions.hidden) {
          this.$tabs_instructions.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_tasks') {
        if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
          this.$tabs_tasks.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .carrier_service
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .vendor_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .container_size
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const result = await $flows.SalesOrders.is_sales_order_deletable_flow({ orderId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete Order', `Are you sure you want to delete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.SalesOrders.delete_sales_order_flow({ orderId: $editor.entity.Id });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted.', reason);
                  return;
              }
              else {
                  $editor.close();
              }
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order delete error', 'An error occurred during the deletion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  var shipmentId = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
  var orderId = $editor.inParams.orderId;
  var orderStatusId = $editor.entity.OrderStatusId;
  
  if ($utils.isDefined(shipmentId)) {
  
      const result = await $flows.SalesOrders.is_sales_order_cancelable_flow({ 
          orderId: $editor.entity.Id,
          shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId });
      const reason = result.reason;
      if ($utils.isDefined(reason)) {
          await $shell.FootPrintManager.openErrorDialog('Order cannot be canceled.', reason);
          return;
      } else {
          const confirm = await $shell.FootPrintManager.openConfirmationDialog('Cancel order', `Are you sure you want to cancel order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
          if (confirm) {
              try {
  
                  if (orderStatusId === 1) {
  
                      const result = await $flows.SalesOrders.cancel_sales_order_flow({
                          orderId: orderId
                      });
                      const reason = result.reason;
  
                      if ($utils.isDefined(reason)) {
                          await $shell.FootPrintManager.openErrorDialog('Order cannot be cancelled.', reason);
                          return;
                      }
                      else {
  
                          const title = 'Cancel Order';
                          const message = `Order ${$editor.entity.LookupCode} cancelled.`;
                          await $shell.FootPrintManager.openInfoDialog(title, message);
                          await $editor.refresh();
                          await $editor.tabs.sales_order_lines.activate();
                      }
  
                  }
                  else {
                      const dialogResult = await $shell.FootPrintManager.opensales_order_cancellation_options_formDialog({
                          title: 'Select a reason code',
                          parentId: 45,
                          parentEntity: 'OperationType'
                      });
                      var userConfirmed = dialogResult.confirm;
                      var reasonCodeId = dialogResult.reasonCodeId;
  
                      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
                          return;
                      }
  
                      if (userConfirmed) {
  
                          const result = await $flows.SalesOrders.cancel_sales_order_shipment_and_order_lines_flow({
                              shipmentId: shipmentId,
                              reasonCodeId: reasonCodeId
                          });
                          const reason = result.reason;
  
                          if ($utils.isDefined(reason)) {
                              await $shell.FootPrintManager.openErrorDialog('Order cannot be cancelled.', reason);
                              return;
                          }
                          else {
  
                              const title = 'Cancel Order';
                              const message = `Order ${$editor.entity.LookupCode} cancelled.`;
                              await $shell.FootPrintManager.openInfoDialog(title, message);
                              await $editor.refresh();
                              await $editor.tabs.sales_order_lines.activate();
                          }
  
                      }
  
                  }
  
              } catch (error) {
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
                  await $shell.FootPrintManager.openErrorDialog('Cancel order error', 'An error occurred during the cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              }
          }
      }
  
  }
  else {
  
      throw new Error('Unable to determine the shipment for the selected order.')
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  const result = await $flows.SalesOrders.is_sales_order_completable_flow({ orderId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Complete Order', `Are you sure you want to complete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.SalesOrders.complete_sales_order_flow({ orderId: $editor.entity.Id });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', reason);
                  return;
              }
              else {
  
                  const title = 'Complete Order';
                  const message = `Order ${$editor.entity.LookupCode} completed.`;
                  await $shell.SalesOrders.openInfoDialog(title, message);
                  await $editor.refresh();
                  await $editor.tabs.shipping_contents.activate();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order complete error', 'An error occurred during the completion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // Set editor title
  $editor.title = `Outbound Order ${$editor.entity.LookupCode}`;
  
  
  var shipTo = '';
  
  $editor.check_can_transfer_outstanding()
      .then(result => $editor.toolbar.transfer_outstanding.hidden = !result);
  
  $editor.fields.clear_parent_order.hidden = true;
  if ($editor.entity.OrderClass.LookupCode.trim().toUpperCase() !== 'SS') {
      $editor.fields.parent_order.hidden = true;
  }
  else {
      $editor.fields.parent_order.hidden = false;
  
      // Update button to show parent order lookup code
      if ($utils.isDefined($editor.entity.ParentOrderId)) {
          let parentOrder = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $editor.entity.ParentOrderId })).result;
  
          $editor.fields.parent_order.control.label = parentOrder.LookupCode;
          $editor.fields.clear_parent_order.hidden = false;
      }
      else {
          $editor.fields.parent_order.control.label = `Select parent order`;
      }
  }
  
  const orderAddress = (await $flows.Addresses.get_address_single_string_by_orderId({ orderId: $editor.inParams.orderId }));
  if ($utils.isDefined(orderAddress)) {
      shipTo = orderAddress.address;
  }
  
  // Only display ship from if it is not an auto generated account
  if ($utils.isDefined(shipTo)) {
      if (!shipTo.startsWith('ord-')) {
          $editor.fields.ship_to.control.text = shipTo;
      } else {
          $editor.fields.ship_to.control.text = '';
      }
  }
  
  
  // Apply appointment text logic
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dockAppointmentId: dockAppointments[0]?.DockAppointmentId,
      })).formattedDockAppointment;
  
  } else {
      // Reset the appointment label back to original
      $editor.toolbar.appointment.control.styles.resetStyle();
      $editor.toolbar.appointment.control.label = 'Appointment';
  }
  
  
  // Fetch shipments
  const shipments = (await $datasources.SalesOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
  if (shipments.length > 1) {
      // close
      await $shell.SalesOrders.openErrorDialog('Invalid order', 'This order has more than one shipment. This editor cannot display this kind of order. Editor will close');
      $editor.close();
      return;
  }
  
  else {
  
      $editor.fields.warehouse.control.readOnly = false;
      $editor.fields.warehouse.required = false;
  
      if (shipments.length === 1) {
          $editor.vars.shipmentId = shipments[0].ShipmentId;
          $editor.fields.warehouse.required = true;
  
      }
  
  
      const noWarehouse = $utils.isDefined($editor.fields.warehouse.control.value) === false;
  
      // created status
      if ($editor.entity.OrderStatusId === 1) {
          $editor.toolbar.cancel.hidden = false;
          $editor.toolbar.on_delete.hidden = false;
          $editor.toolbar.on_delete.control.readOnly = false;
  
          // Reset process button in case the editor is refreshed back to a created status
          $editor.toolbar.process.hidden = false;
          $editor.toolbar.process.control.styles.resetStyle();
          $editor.toolbar.process.control.label = 'Process';
          $editor.toolbar.process.control.readOnly = false;
          $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--Process";
  
  
  
  
          // disable process if no warehouse
          $editor.toolbar.process.control.readOnly = noWarehouse;
  
          $editor.toolbar.auto_load.hidden = true;
          $editor.toolbar.auto_pick.hidden = true;
          $editor.toolbar.complete.hidden = true;
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
  
  
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.carrier.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.order_total_picked_gross_widget.hidden = true;
          $editor.widgets.order_total_picked_units_widget.hidden = true;
  
          if ($editor.tabs.picking_tasks.active) {
              $editor.tabs.sales_order_lines.activate();
          }
  
          $editor.tabs.picking_tasks.hidden = true;
          $editor.tabs.manual_allocation_tasks.hidden = true;
          $editor.tabs.shipping_contents.hidden = true;
  
  
      } else if ($editor.entity.OrderStatusId === 2) {
          // processing status
  
  
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.carrier.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
  
          const shipmentContents = (await $datasources.SalesOrders.ds_get_shipmentscontents_by_orderId_shipmentId_top1.get({
              orderId: $editor.inParams.orderId,
              shipmentId: $editor.vars.shipmentId
          })).result;
  
          // Revert button and Shipping Contents 'Picked' tab
          if ($utils.isDefined(shipmentContents)) {
  
              $editor.tabs.shipping_contents.hidden = false;
              //  $editor.tabs.reports.tabs.bol_report.hidden = false;
          } else {
  
              $editor.tabs.shipping_contents.hidden = true;
              //  $editor.tabs.reports.tabs.bol_report.hidden = true;
          }
  
          // Set custom status
          let stateId: number = 1;
          let has_manual: boolean = false;
          let stateName: string = $editor.entity.Status.Name;
  
          const orders = [{
              orderId: $editor.entity.Id,
              shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
          }];
  
          const order_states = (await $flows.SalesOrders.get_sales_order_state({ orders: orders })).states;
  
          if (order_states.length > 0) {
              stateId = order_states[0].stateId;
              stateName = order_states[0].stateName;
              has_manual = order_states[0].has_manual;
          };
  
          $editor.vars.orderState = stateId;
  
          // Set manual allocations
          var manualAllocations;
          if ($utils.isDefined(has_manual)) {
  
              if (has_manual) {
                  $editor.tabs.manual_allocation_tasks.hidden = false;
                  manualAllocations = true;
              } else {
                  manualAllocations = false;
                  $editor.tabs.manual_allocation_tasks.hidden = true;
              }
          } else {
              manualAllocations = false;
              $editor.tabs.manual_allocation_tasks.hidden = true;
          }
  
          if ($utils.isDefined(stateId)) {
              switch (stateId) {
  
                  case 2: {
                      $editor.fields.order_state.control.text = stateName
                      $editor.toolbar.cancel.hidden = false;
                      $editor.toolbar.cancel.control.readOnly = false;
  
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.process.control.readOnly = false;
  
                      $editor.toolbar.on_delete.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
  
                      break;
                  }
                  case 3: {
                      $editor.fields.order_state.control.text = stateName
                      $editor.toolbar.cancel.hidden = false;
                      $editor.toolbar.cancel.control.readOnly = false;
  
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.process.control.readOnly = false;
  
                      $editor.toolbar.on_delete.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
  
                      break;
                  }
                  case 4: {
                      $editor.fields.order_state.control.text = stateName
                      $editor.toolbar.cancel.hidden = false;
                      $editor.toolbar.cancel.control.readOnly = false;
  
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.process.control.readOnly = false;
  
                      $editor.toolbar.on_delete.control.readOnly = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
                      // $editor.tabs.reports.tabs.pick_slip_report.hidden = false;
  
                      break;
                  }
                  // All pick tasks in a released status
                  case 5: {
                      $editor.fields.order_state.control.text = stateName
                      $editor.toolbar.cancel.hidden = false;
                      $editor.toolbar.cancel.control.readOnly = false;
  
                      $editor.toolbar.auto_pick.hidden = false;
  
                      $editor.toolbar.on_delete.control.readOnly = true;
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = false;
  
                      //  $editor.tabs.reports.tabs.pick_slip_report.hidden = false;
  
                      break;
                  } // One or more pick tasks completed and one or more pick tasks released
                  case 6: {
                      $editor.fields.order_state.control.text = stateName
                      $editor.toolbar.auto_pick.hidden = false;
  
                      $editor.toolbar.cancel.control.readOnly = true;
                      $editor.toolbar.on_delete.control.readOnly = true;
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = false;
  
                      //  $editor.tabs.reports.tabs.pick_slip_report.hidden = false;
  
                      break;
                  } // No released pick tasks exist and one or more shipping licenseplates require a loading task
                  case 7: {
                      $editor.fields.order_state.control.text = stateName
                      $editor.toolbar.auto_load.hidden = false;
  
                      $editor.toolbar.cancel.control.readOnly = true;
                      $editor.toolbar.on_delete.control.readOnly = true;
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
  
                      //   $editor.tabs.reports.tabs.pick_slip_report.hidden = true;
  
  
                      break;
                  } // No released pick tasks or manual allocations exist and all shipping licenseplates loaded
                  case 8: {
                      if (manualAllocations) {
                          $editor.fields.order_state.control.text = stateName
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = true;
                      } else {
                          $editor.fields.order_state.control.text = stateName
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = false;
  
                          //  $editor.tabs.reports.tabs.pick_slip_report.hidden = true;
                      }
  
                      $editor.toolbar.cancel.control.readOnly = true;
                      $editor.toolbar.on_delete.control.readOnly = true;
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
  
  
                      break;
                  }
                  // No released pick tasks or manual allocations exist and all shipping contents unpicked
                  case 9: {
                      if (manualAllocations) {
                          $editor.fields.order_state.control.text = stateName
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = true;
                          $editor.toolbar.cancel.hidden = false;
                          $editor.toolbar.cancel.control.readOnly = true;
                      } else {
                          $editor.fields.order_state.control.text = stateName
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = true;
                          $editor.toolbar.cancel.hidden = false;
                          $editor.toolbar.cancel.control.readOnly = false;
                      }
  
  
                      $editor.toolbar.on_delete.control.readOnly = true;
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
  
  
  
                      break;
                  }
  
  
              }
          } else {
              if (manualAllocations) {
                  $editor.fields.order_state.control.text = 'Manual Allocations'
                  $editor.toolbar.complete.hidden = false;
                  $editor.toolbar.complete.control.readOnly = true;
              }
              $editor.toolbar.process.hidden = true;
              $editor.toolbar.auto_load.hidden = true;
              $editor.toolbar.auto_pick.hidden = true;
  
              $editor.tabs.reports.tabs.pick_slip_report.hidden = true;
  
          }
          // Widgets
          $editor.widgets.order_total_picked_gross_widget.hidden = false;
          $editor.widgets.order_total_picked_units_widget.hidden = false;
  
  
  
      } else {
          $editor.toolbar.process.hidden = true;
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.complete.hidden = true;
          $editor.toolbar.on_delete.hidden = true;
          $editor.toolbar.auto_load.hidden = true;
          $editor.toolbar.auto_pick.hidden = true;
  
  
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = true;
          $editor.fields.carrier.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.expected_date.control.readOnly = true;
  
          // Widgets
          $editor.widgets.order_total_picked_gross_widget.hidden = false;
          $editor.widgets.order_total_picked_units_widget.hidden = false;
  
          $editor.tabs.picking_tasks.hidden = true;
  
          $editor.tabs.shipping_contents.hidden = false;
          // $editor.tabs.reports.tabs.bol_report.hidden = false;
          //  $editor.tabs.reports.tabs.pick_slip_report.hidden = true;
  
  
  
      }
  
  
  }
  
  $editor.apply_operations();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $editor.tabs.picking_tasks.hidden = true;
  $editor.tabs.manual_allocation_tasks.hidden = true;
  $editor.tabs.shipping_contents.hidden = true;
  
  // Set Date/Time Formats
  $editor.fields.expected_date.control.format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Create shipment if none found
  
  const shipments = (await $datasources.SalesOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(shipments)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  
      
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "CarrierServiceTypeId": order.PreferredCarrierServiceTypeId,
                  "ExpectedWarehouseId": order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 2
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
  
      }
  
  
  
  }
  
  // Set warehouse on order if blank and shipment contains expected warehouse
  const orders = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(orders.PreferredWarehouseId) && $utils.isDefined(shipments[0]?.Shipment?.ExpectedWarehouseId)) {
  
      let payload: any = {};
  
      payload.PreferredWarehouseId = shipments[0]?.Shipment?.ExpectedWarehouseId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.inParams.orderId, entity: payload });
  
  }
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const processingStrategy = 'D2617377-7ABC-4384-A27B-F8D7010CC27E';
  
  var section = 'order processing';
  
  try {
      const orderlines = (await $datasources.SalesOrders.ds_get_orderlines_by_orderId_without_children.get({ orderId: $editor.entity.Id })).result;
      if (!$utils.isDefined(orderlines)) {
          throw new Error('Order must have at least one order line to process.')
      }
  
      // Order Substitution Flow Execution
      section = 'order substitution';
      const sub = await $flows.SalesOrders.substitution_sales_order_flow({ orderId: $editor.entity.Id });
      if ($utils.isDefined(sub.reason)) {
          throw new Error(sub.reason);
      }
  
      section = 'order processing';
      const isProcessable = await $flows.SalesOrders.is_sales_order_processable_flow({ orderId: $editor.entity.Id });
  
      if ($utils.isDefined(isProcessable.reason)) {
          throw new Error(isProcessable.reason);
      }
  
      /*** Prompt for processing sales order options ***/
      const dialogResult = await $shell.FootPrintManager.opensales_orders_processing_options_formDialog({
          order_ids: [$editor.entity.Id],
          default_waving: true}, 'modal');
  
      if (!dialogResult.confirm) { return; }
  
      dialogResult.waveOptions.priority = $utils.isDefined(dialogResult.waveOptions?.priority) ? dialogResult.waveOptions.priority : 1;
      dialogResult.waveOptions.description = $utils.isDefined(dialogResult.waveOptions?.description) ? dialogResult.waveOptions.description : `Created via processing flow`;
      dialogResult.waveOptions.isCreateManualAllocationTasks = dialogResult.waveOptions?.isCreateManualAllocationTasks ?? false;
  
      let targetState = 2 // process order
      if (dialogResult.waveOptions.isCreateWave) { targetState = 3; }
      if (dialogResult.waveOptions.isProcessWave) { targetState = 4; }
      if (dialogResult.waveOptions.isReleaseWave) { targetState = 5; }
  
      let processingStrategyWorkflowCode = dialogResult.processingStrategyWorkflowId;
  
      // Begin processing
      $editor.toolbar.process.control.readOnly = true;
      $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
  
      $editor.toolbar.cancel.control.readOnly = true;
      $editor.toolbar.on_delete.control.readOnly = true;
  
      // Set custom status
      let stateId: number = 1;
   
      const orders = [{
          orderId: $editor.entity.Id,
          shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
      }];
   
      const order_states = (await $flows.SalesOrders.get_sales_order_state({ orders: orders })).states;
   
      if (order_states.length > 0) {
          stateId = order_states[0].stateId;
      };
   
      for (let i = stateId; i < targetState; i++) {
          switch (i) {
              case 1: {
                  section = 'order processing';
                  let soft = await $flows.SalesOrders.is_sales_order_soft_allocatable_flow({
                      orderId: $editor.entity.Id
                  });
  
                  // Check for order lines with child serials
                  let child_serial_lines = (await $datasources.SalesOrders.ds_get_order_lines_with_serial_numbers.get({ order_id: $editor.entity.Id})).result
                      .filter(ol => ol.StatusId === 1 && !ol.HasChildren && $utils.isDefined(ol.SerialNumber?.ChainHead))
  
                  let break_child_serials = false;
                  if ($utils.isDefined(child_serial_lines)) {
                      break_child_serials = await $shell.FootPrintManager.openConfirmationDialog('Order lines contain child serial numbers', 'In order to proceed, the selected child serials must be broken from their parent. Do you want to break the child serial numbers?', 'Break serials', 'Cancel') ?? false;
  
                      if (!break_child_serials) {
                          $editor.refresh();
                          return;
                      }
                  }
  
                  // Soft allocation passed continue to processing the order
                  $editor.toolbar.process.control.label = 'Processing'
                  let result = await $flows.SalesOrders.process_sales_order_flow({
                      order_id: $editor.entity.Id,
                      shipment_id: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                      processing_strategy_workflow_code: processingStrategyWorkflowCode,
                      break_child_serials: break_child_serials
                  });
  
                  if ($utils.isDefined(result.reason)) {
                      throw new Error(result.reason);
                  }
  
                  break;
              }
              case 2: {
                  section = 'wave creation';
                  $editor.toolbar.process.control.label = 'Creating Wave'
                  let result = await $flows.SalesOrders.create_wave_sales_order_flow({
                      orderId: $editor.entity.Id,
                      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
                  });
  
                  if ($utils.isDefined(result.reason)) {
                      throw new Error(result.reason);
                  }
  
                  break;
              }
              case 3: {
                  section = 'wave processing';
                  $editor.toolbar.process.control.label = 'Processing Wave'
                  let result = await $flows.SalesOrders.process_wave_sales_order_flow({
                      orderId: $editor.entity.Id,
                      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                      is_create_manual_allocation_tasks: dialogResult.waveOptions.isCreateManualAllocationTasks
                  });
  
                  if ($utils.isDefined(result.reason)) {
                      throw new Error(result.reason);
                  }
  
                  break;
              }
              case 4: {
                  section = 'wave release';
                  $editor.toolbar.process.control.label = 'Releasing Wave'
                  let result = await $flows.SalesOrders.release_wave_sales_order_flow({
                      orderId: $editor.entity.Id,
                      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
                  });
  
                  if ($utils.isDefined(result.reason)) {
                      throw new Error(result.reason);
                  }
  
                  break;
              }
          }
      }
  
      section = 'order processing';
  
      await $shell.FootPrintManager.openInfoDialog('Successfully processed order', `Order ${$editor.entity.LookupCode} has been processed.`);
      await $editor.tabs.picking_tasks.activate();
  
  
  } catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog(`Error during ${section}`, error.message);
  }
  
  
  $editor.toolbar.process.control.styles.resetStyle();
  $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--Process";
  $editor.toolbar.process.control.label = 'Process';
  
  $editor.toolbar.process.control.readOnly = false;
  $editor.toolbar.cancel.control.readOnly = false;
  $editor.toolbar.on_delete.control.readOnly = false;
  
  $editor.refresh();
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  var shipmentId = $editor.vars.shipmentId;
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
      let orderClassId = (await $datasources.SalesOrders.ds_orderclasses_dd.get({fullTextSearch: 'DEST'})).result
      if ($editor.fields.order_class.control.value !== orderClassId[0].Id) {
          $editor.fields.ship_to_clear.hidden = true;
          $editor.fields.ship_to_selection.hidden = true;
          $editor.on_ship_to_clear();
      } else {
          $editor.fields.ship_to_clear.hidden = false;
          $editor.fields.ship_to_selection.hidden = false;
      }
  }
  if ($editor.fields.owner_reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.owner_reference.control.value;
  }
  if ($editor.fields.vendor_reference.control.isChanged) {
      payload.VendorReference = $editor.fields.vendor_reference.control.value;
  }
  
  if ($editor.fields.warehouse.control.isChanged) {
      if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
          throw new Error('Please select a warehouse.')
      }
      else {
          payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
      }
  }
  if ($editor.fields.expected_date.control.isChanged) {
      payload.RequestedDeliveryDate = $editor.fields.expected_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  // Create the shipment if it does not exist only if the order has a preferred warehouse of if the preferred warehouse is selected in the editor
  if (!$utils.isDefined(shipmentId)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  
  
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId) || $utils.isAllDefined(order, $editor.fields.warehouse.control.value)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "CarrierServiceTypeId": order.PreferredCarrierServiceTypeId,
                  "ExpectedWarehouseId": $utils.isDefined($editor.fields.warehouse.control.value) ? $editor.fields.warehouse.control.value : order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 2
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
          shipmentId = nextShipmentId;
          $editor.vars.shipmentId = nextShipmentId;
  
      }
  
  
  }
  
  if ($utils.isDefined(shipmentId)) {
  
      payload = {};
  
  
      if ($editor.fields.carrier.control.isChanged) {
          payload.CarrierId = $editor.fields.carrier.control.value;
          // Clear the carrier service type as it could conflict with the newly selected carrier
          payload.CarrierServiceTypeId = null;
          $editor.fields.carrier_service.control.value = null;
          $editor.fields.carrier_service.control.displayText = null;
      }
      if ($editor.fields.carrier_service.control.isChanged) {
          payload.CarrierServiceTypeId = $editor.fields.carrier_service.control.value;
      }
      if ($editor.fields.warehouse.control.isChanged) {
          if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
              throw new Error('Please select a warehouse.')
          }
          else {
              payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
          }
      }
      if ($editor.fields.expected_date.control.isChanged) {
          payload.ExpectedDate = $editor.fields.expected_date.control.value;
      }
  
  
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
  
      if ($editor.fields.container_size.control.isChanged) {
          if ($utils.isDefined($editor.fields.container_size.control.value)) {
              const container = (await $datasources.custom_ds_get_container_size_by_containerId.get({
                  containerId: $editor.fields.container_size.control.value
              })).result;
              if ($utils.isDefined(container)) {
                  payload.ContainerSize = container.Name;
              }
          }
          else {
              payload.ContainerSize = null
          }
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: $editor.vars.shipmentId, entity: payload });
  
  
  }
  
  await $editor.refresh();
  }
  on_auto_pick_clicked(event = null) {
    return this.on_auto_pick_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_pick_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const result = await $flows.SalesOrders.is_sales_order_pickable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
  });
  
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be picked.', reason);
      return;
  } else {
  
      var warehouseId = $editor.entity.PreferredWarehouseId
      if (!$utils.isDefined(warehouseId)) {
  
          $shell.FootPrintManager.openErrorDialog('Order Auto Pick Error', 'Unable to determine preferred warehouse from first order selected');
          return;
      }
  
      const dialogResult = await $shell.FootPrintManager.openauto_pick_options_formDialog({ warehouse_id: warehouseId });
      var userConfirmed = dialogResult.confirm;
      var autoLoadConfirmed = dialogResult.auto_load;
      var loadLocation = dialogResult.load_location;
      let license_plate_lookup = dialogResult.license_plate_lookup;
  
      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
          return;
      }
  
      if (userConfirmed) {
          try {
  
              const result = await $flows.SalesOrders.auto_pick_sales_order_flow({
                  orderId: $editor.entity.Id,
                  shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
              });
              const reason = result.reason;
  
              if ($utils.isAllDefined(userConfirmed, autoLoadConfirmed) && autoLoadConfirmed === true) {
  
                  const flowLoadParams = {
                      orderId: $editor.entity.Id,
                      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                      loadLocation: $utils.isDefined(loadLocation) ? loadLocation : null,
                      license_plate_lookup: license_plate_lookup
                  }
                  const resultLoad = await $flows.SalesOrders.auto_load_sales_order_flow(flowLoadParams);
                  var reasonLoad = resultLoad.reason;
              }
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be fully auto picked.', reason);
                  await $editor.refresh();
                  await $editor.tabs.picking_tasks.activate();
                  return;
              }
              else if ($utils.isDefined(reasonLoad)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be fully auto loaded.', reasonLoad);
                  await $editor.refresh();
                  await $editor.tabs.shipping_contents.activate();
                  return;
              }
              else {
  
                  const title = 'Auto Pick Order';
                  const loadmessage = (autoLoadConfirmed === true) ? ' and fully auto loaded.' : '.'
                  const message = `Order ${$editor.entity.LookupCode} has been fully auto picked${loadmessage}`;
                  await $shell.SalesOrders.openInfoDialog(title, message);
                  await $editor.refresh();
                  await $editor.tabs.shipping_contents.activate();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Auto pick order error', 'An error occurred during the auto picking of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              await $editor.refresh();
              await $editor.tabs.picking_tasks.activate();
          }
      }
  }
  }
  on_auto_load_clicked(event = null) {
    return this.on_auto_load_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_load_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const result = await $flows.SalesOrders.is_sales_order_loadable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
  });
  
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be loaded.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Auto load order', `Are you sure you want to auto load the entire order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              const result = await $flows.SalesOrders.auto_load_sales_order_flow({
                  orderId: $editor.entity.Id,
                  shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
              });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be fully auto loaded.', reason);
                  return;
              }
              else {
  
                  const title = 'Auto Load Order';
                  const message = `Order ${$editor.entity.LookupCode} has been fully auto loaded.`;
                  await $shell.SalesOrders.openInfoDialog(title, message);
                  await $editor.refresh();
                  await $editor.tabs.shipping_contents.activate();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Auto load order error', 'An error occurred during the auto loading of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId
      }, 'modal', EModalSize.Large);
  
  } else {
      // Check if the selected order is in a rejected status and throw an error.
      if ($editor.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
  
          warehouseId: [$editor.entity.PreferredWarehouseId],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $editor.entity.RequestedDeliveryDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $editor.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 2,
          ownerId: $editor.entity.Project.OwnerId,
          projectId: $editor.entity.ProjectId,
          carrierId: $editor.entity.PreferredCarrierId,
          orderId: $editor.inParams.orderId
      });
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  
  await $shell.SalesOrders.openload_slip_reportDialog({ orderId: $editor.inParams.orderId });
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  //Get the temperature capture operation
  
  if (await $operations.FootPrintManager.Disable_SalesOrders_AutoPicking.isAuthorized()) {
      $editor.toolbar.auto_pick.hidden = true;
  } else {
      $editor.toolbar.auto_pick.hidden = false;
  }
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'Order', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
      await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$editor.entity.ShipmentOrderLookups[0].ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'modal');
  }
  
  }
  on_ship_to_clicked(event = null) {
    return this.on_ship_to_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_to_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const dialogResult = await $shell.FootPrintManager.openownersaccounts_gridDialog({
      ownerId: $editor.entity.Project.OwnerId,
      typeIds: [1, 3, 4, 5],
      accountTypeId: 2,
      allowSelection: true
  });
  
  const userConfirmed = dialogResult.confirm; 
  const accountId = dialogResult.accountId; 
  const addressId = dialogResult.addressId;
  
  if(userConfirmed && $utils.isAllDefined(accountId, addressId)){
      let payload: any = {};
  
      payload.AccountId = accountId; 
      payload.BillingAddresId = addressId; 
  
      await $flows.Utilities.crud_update_flow({entitySet: 'Orders', id: $editor.entity.Id, entity: payload});
  }
  
  await $editor.refresh();
  }
  on_ship_to_clear(event = null) {
    return this.on_ship_to_clearInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_to_clearInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  let payload: any = {}; 
  
  const originalAccountId = (await $datasources.custom_ds_get_original_default_account_id.get({lookupCode: `ord-${$editor.inParams.orderId}`})).result
  
  payload.AccountId = originalAccountId.Id; 
  payload.BillingAddresId = null; 
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  $editor.fields.ship_to.control.text = '';
  
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'Order', entityKeys: [{name: 'Id', value: $editor.inParams.orderId}]}, 'modal');
  }
  on_parent_order_clicked(event = null) {
    return this.on_parent_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_parent_order_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($editor.entity.ParentOrderId)) {
      // Open editor for parent order
      $shell.opencustom_sales_order_editor({ orderId: $editor.entity.ParentOrderId});
  }
  else {
      // Search parent order and link
      let result = (await $shell.FootPrintManager.openoutbound_orders_eligible_for_return_gridDialog({ projectId: $editor.entity.ProjectId }));
  
      if ($utils.isDefined(result?.orderIds)) {
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Orders',
              id: $editor.entity.Id,
              entity: {
                  ParentOrderId: result.orderIds[0]
              }
          });
  
          $editor.refresh();
      }
  }
  }
  on_clear_parent_order_clicked(event = null) {
    return this.on_clear_parent_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_clear_parent_order_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  await $flows.Utilities.crud_update_flow({
      entitySet: 'Orders',
      id: $editor.entity.Id, 
      entity: {
          ParentOrderId: null
      }
  });
  
  $editor.refresh();
  }
  on_transfer_outstanding_clicked(event = null) {
    return this.on_transfer_outstanding_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_transfer_outstanding_clickedInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  try {
      if (!(await $shell.openConfirmationDialog('Transfer remaining content to a new order?', 'This cannot be undone.', 'Transfer', 'Cancel'))) { return; }
  
      const result = await $flows.transfer_licenseplates_to_new_salesorder_flow({ order_id: $editor.entity.Id });
  
      if ($utils.isDefined(result.reasons)) {
          throw new Error(result.reasons.join(', '));
      }
  
      $shell.openToaster('Content transferred', null, EToasterType.Success, { positionClass: EToasterPosition.topRight, timeOut: 5000, progressBar: false, tapToDismiss: true });
      $editor.refresh();
      
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      $shell.openToaster('Error transferring content', targetError.message, EToasterType.Error, { positionClass: EToasterPosition.topRight, timeOut: 10000, progressBar: true, tapToDismiss: false });
  }
  }
  check_can_transfer_outstanding(event = null) {
    return this.check_can_transfer_outstandingInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async check_can_transfer_outstandingInternal(
    $editor: app_custom_sales_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // Find unloaded LPs
  const p_lps = $datasources.custom_ds_get_shipping_license_plates.get({ order_id: $editor.entity.Id });
  
  // Find planned/released picking tasks
  const p_openTasks = $datasources.SalesOrders.ds_get_released_picking_tasks_by_orderId_shipmentId_top1.get({ orderId: $editor.entity.Id, shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId });
  
  const [{result: lps}, {result: openTasks}] = await Promise.all([p_lps, p_openTasks]);
  
  // Return TRUE if there are lps loaded AND there are unloaded LPs or released tasks
  return lps.some(lp => $utils.isDefined(lp.TasksForActualTargetLicensePlate)) && (lps.some(lp => !$utils.isDefined(lp.TasksForActualTargetLicensePlate)) || $utils.isDefined(openTasks));
  }
  //#endregion private flows
}
