import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_ds_jobs_header_by_orderId_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { fullTextSearch?: string, orderId?: string, warehouseId?: number, projectIds?: number[], $top?: number, $skip?: number }): Promise<{ result?: { jobId?: string, jobPriority?: number, toteId?: string, jobDate?: string, status?: string, jobTaskCount?: number, bot?: string, project?: string, warehouse?: string, customer?: string, orderId?: string, shippingContainer?: string, licensePlate?: string, botType?: string, orderLookup?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/ds_jobs_header_by_orderId_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { fullTextSearch?: string, orderId?: string, warehouseId?: number, projectIds?: number[], $keys: string[] }): Promise<{ result?: { jobId?: string, jobPriority?: number, toteId?: string, jobDate?: string, status?: string, jobTaskCount?: number, bot?: string, project?: string, warehouse?: string, customer?: string, orderId?: string, shippingContainer?: string, licensePlate?: string, botType?: string, orderLookup?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LocusRobotics/datasources/ds_jobs_header_by_orderId_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
