<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="process" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_process_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="validate" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_validate_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="auto_receive" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_auto_receive_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="complete" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_complete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="unreceive" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_unreceive_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="revert" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_revert_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="appointment" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_appointment_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="update_project" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_update_project_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="auto_transfer" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_auto_transfer_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="on_delete" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_delete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator4" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="print" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_print_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="attachments" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_attachments_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="surveys" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_surveys_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Order</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_class.label + (fields.order_class.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_class.label}}<span *ngIf="fields.order_class.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <AsnOrders-orderclasses_dd_single 
                            data-cy="selector"
                            [type]="fields.order_class.control.type"
                            formControlName="order_class"
                            (displayTextChange)="fields.order_class.control.displayText=$event"
                            [placeholder]="fields.order_class.control.placeholder"
                            [styles]="fields.order_class.control.styles"
                            [tooltip]="fields.order_class.control.tooltip"
                        >
                        </AsnOrders-orderclasses_dd_single>
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.owner.label + (fields.owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.owner.label}}<span *ngIf="fields.owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                            [tooltip]="fields.owner.control.tooltip"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_status" *ngIf="!fields.order_status.hidden" 
                            class="field-container standard {{fields.order_status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_status.styles.style"
                            [ngClass]="fields.order_status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_status.label + (fields.order_status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_status.label}}<span *ngIf="fields.order_status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.order_status.control.styles.style"
                              [ngClass]="fields.order_status.control.styles.classes"
                              [matTooltip]="fields.order_status.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.order_status.control.text }}</div>
                        <ng-container *ngIf="fields.order_status.invalid">
                          <ng-container *ngFor="let error of fields.order_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-hot_receipt" *ngIf="!fields.hot_receipt.hidden" 
                            class="field-container standard {{fields.hot_receipt.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.hot_receipt.styles.style"
                            [ngClass]="fields.hot_receipt.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.hot_receipt.label + (fields.hot_receipt.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.hot_receipt.label}}<span *ngIf="fields.hot_receipt.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="hot_receipt"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.hot_receipt.control.styles.style"
                                      [ngClass]="fields.hot_receipt.control.styles.classes"
                                      [matTooltip]="fields.hot_receipt.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.hot_receipt.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.hot_receipt.invalid">
                          <ng-container *ngFor="let error of fields.hot_receipt.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reference" *ngIf="!fields.reference.hidden" 
                            class="field-container standard {{fields.reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reference.styles.style"
                            [ngClass]="fields.reference.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reference.label + (fields.reference.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reference.label}}<span *ngIf="fields.reference.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.reference.control.placeholder}}"
                                [ngStyle]="fields.reference.control.styles.style"
                                [ngClass]="fields.reference.control.styles.classes"
                                [matTooltip]="fields.reference.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.reference.invalid">
                          <ng-container *ngFor="let error of fields.reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-ship_from_selection" *ngIf="!fields.ship_from_selection.hidden" 
                            class="field-container standard {{fields.ship_from_selection.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_from_selection.styles.style"
                            [ngClass]="fields.ship_from_selection.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_from_selection.label + (fields.ship_from_selection.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_from_selection.label}}<span *ngIf="fields.ship_from_selection.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_from_selection.control.readOnly"
                                [ngStyle]="fields.ship_from_selection.control.styles.style"
                                [ngClass]="fields.ship_from_selection.control.styles.classes"
                                [matTooltip]="fields.ship_from_selection.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_ship_from_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_from_selection.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_from_selection.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_from_selection.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_from_selection.invalid">
                          <ng-container *ngFor="let error of fields.ship_from_selection.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_from_clear" *ngIf="!fields.ship_from_clear.hidden" 
                            class="field-container standard {{fields.ship_from_clear.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_from_clear.styles.style"
                            [ngClass]="fields.ship_from_clear.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_from_clear.label + (fields.ship_from_clear.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_from_clear.label}}<span *ngIf="fields.ship_from_clear.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_from_clear.control.readOnly"
                                [ngStyle]="fields.ship_from_clear.control.styles.style"
                                [ngClass]="fields.ship_from_clear.control.styles.classes"
                                [matTooltip]="fields.ship_from_clear.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_ship_from_cleared($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_from_clear.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_from_clear.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_from_clear.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_from_clear.invalid">
                          <ng-container *ngFor="let error of fields.ship_from_clear.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_from" *ngIf="!fields.ship_from.hidden" 
                            class="field-container double {{fields.ship_from.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_from.styles.style"
                            [ngClass]="fields.ship_from.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_from.label + (fields.ship_from.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_from.label}}<span *ngIf="fields.ship_from.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.ship_from.control.styles.style"
                              [ngClass]="fields.ship_from.control.styles.classes"
                              [matTooltip]="fields.ship_from.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.ship_from.control.text }}</div>
                        <ng-container *ngIf="fields.ship_from.invalid">
                          <ng-container *ngFor="let error of fields.ship_from.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_reference" *ngIf="!fields.vendor_reference.hidden" 
                            class="field-container standard {{fields.vendor_reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vendor_reference.styles.style"
                            [ngClass]="fields.vendor_reference.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_reference.label + (fields.vendor_reference.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_reference.label}}<span *ngIf="fields.vendor_reference.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="vendor_reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.vendor_reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendor_reference.control.placeholder}}"
                                [ngStyle]="fields.vendor_reference.control.styles.style"
                                [ngClass]="fields.vendor_reference.control.styles.classes"
                                [matTooltip]="fields.vendor_reference.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.vendor_reference.invalid">
                          <ng-container *ngFor="let error of fields.vendor_reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_date" *ngIf="!fields.expected_date.hidden" 
                            class="field-container standard {{fields.expected_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.expected_date.styles.style"
                            [ngClass]="fields.expected_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_date.label + (fields.expected_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_date.label}}<span *ngIf="fields.expected_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expected_date"
                            [format]="fields.expected_date.control.format"
                            [mode]="fields.expected_date.control.mode"
                            [ngStyle]="fields.expected_date.control.styles.style"
                            [ngClass]="fields.expected_date.control.styles.classes"
                            [tooltip]="fields.expected_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.expected_date.invalid">
                          <ng-container *ngFor="let error of fields.expected_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-priority" *ngIf="!fields.priority.hidden" 
                            class="field-container standard {{fields.priority.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.priority.styles.style"
                            [ngClass]="fields.priority.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.priority.label + (fields.priority.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.priority.label}}<span *ngIf="fields.priority.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="priority"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.priority.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.priority.control.placeholder}}"
                                [ngStyle]="fields.priority.control.styles.style"
                                [ngClass]="fields.priority.control.styles.classes"
                                [matTooltip]="fields.priority.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.priority.invalid">
                          <ng-container *ngFor="let error of fields.priority.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-custom_fields" *ngIf="!fields.custom_fields.hidden" 
                            class="field-container standard {{fields.custom_fields.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.custom_fields.styles.style"
                            [ngClass]="fields.custom_fields.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.custom_fields.label + (fields.custom_fields.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.custom_fields.label}}<span *ngIf="fields.custom_fields.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.custom_fields.control.readOnly"
                                [ngStyle]="fields.custom_fields.control.styles.style"
                                [ngClass]="fields.custom_fields.control.styles.classes"
                                [matTooltip]="fields.custom_fields.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_custom_fields_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.custom_fields.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.custom_fields.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.custom_fields.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.custom_fields.invalid">
                          <ng-container *ngFor="let error of fields.custom_fields.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup3"
                     *ngIf="!fieldsets.newGroup3.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text">FDA Hold</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-fda_hold" *ngIf="!fields.fda_hold.hidden" 
                            class="field-container standard {{fields.fda_hold.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.fda_hold.styles.style"
                            [ngClass]="fields.fda_hold.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.fda_hold.label + (fields.fda_hold.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.fda_hold.label}}<span *ngIf="fields.fda_hold.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="fda_hold"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.fda_hold.control.styles.style"
                                      [ngClass]="fields.fda_hold.control.styles.classes"
                                      [matTooltip]="fields.fda_hold.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.fda_hold.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.fda_hold.invalid">
                          <ng-container *ngFor="let error of fields.fda_hold.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-awb_number" *ngIf="!fields.awb_number.hidden" 
                            class="field-container standard {{fields.awb_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.awb_number.styles.style"
                            [ngClass]="fields.awb_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.awb_number.label + (fields.awb_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.awb_number.label}}<span *ngIf="fields.awb_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="awb_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.awb_number.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.awb_number.control.placeholder}}"
                                [ngStyle]="fields.awb_number.control.styles.style"
                                [ngClass]="fields.awb_number.control.styles.classes"
                                [matTooltip]="fields.awb_number.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.awb_number.invalid">
                          <ng-container *ngFor="let error of fields.awb_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-entry_number" *ngIf="!fields.entry_number.hidden" 
                            class="field-container standard {{fields.entry_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.entry_number.styles.style"
                            [ngClass]="fields.entry_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.entry_number.label + (fields.entry_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.entry_number.label}}<span *ngIf="fields.entry_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="entry_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.entry_number.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.entry_number.control.placeholder}}"
                                [ngStyle]="fields.entry_number.control.styles.style"
                                [ngClass]="fields.entry_number.control.styles.classes"
                                [matTooltip]="fields.entry_number.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.entry_number.invalid">
                          <ng-container *ngFor="let error of fields.entry_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-fda_order" *ngIf="!fields.fda_order.hidden" 
                            class="field-container standard {{fields.fda_order.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.fda_order.styles.style"
                            [ngClass]="fields.fda_order.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.fda_order.label + (fields.fda_order.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.fda_order.label}}<span *ngIf="fields.fda_order.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.fda_order.control.readOnly"
                                [ngStyle]="fields.fda_order.control.styles.style"
                                [ngClass]="fields.fda_order.control.styles.classes"
                                [matTooltip]="fields.fda_order.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_fda_order_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.fda_order.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.fda_order.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.fda_order.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.fda_order.invalid">
                          <ng-container *ngFor="let error of fields.fda_order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
        <div class="widgets">
          <div *ngIf="!widgets.orderline_total_amount_widget.hidden" class="widget-container">
            <AsnOrders-orderline_total_amount_widget
            #$widgets_orderline_total_amount_widget 
              [orderId]="$widgets_orderline_total_amount_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_orderline_total_amount_widget')"
            >
            </AsnOrders-orderline_total_amount_widget>
          </div>
          <div *ngIf="!widgets.orderline_total_received_widget.hidden" class="widget-container">
            <AsnOrders-orderline_total_received_widget
            #$widgets_orderline_total_received_widget 
              [orderId]="$widgets_orderline_total_received_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_orderline_total_received_widget')"
            >
            </AsnOrders-orderline_total_received_widget>
          </div>
          <div *ngIf="!widgets.orderline_total_gross_received_widget.hidden" class="widget-container">
            <AsnOrders-orderline_total_gross_received_widget
            #$widgets_orderline_total_gross_received_widget 
              [orderId]="$widgets_orderline_total_gross_received_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_orderline_total_gross_received_widget')"
            >
            </AsnOrders-orderline_total_gross_received_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.licenseplates.hidden" class="tab" data-cy="tab-licenseplates">
              <h2 [className]="tabs.licenseplates.active? 'active': ''" (click)="tabs.licenseplates.activate()">{{tabs.licenseplates.title}}</h2>
            </div>
            <div *ngIf="!tabs.orderlines.hidden" class="tab" data-cy="tab-orderlines">
              <h2 [className]="tabs.orderlines.active? 'active': ''" (click)="tabs.orderlines.activate()">{{tabs.orderlines.title}}</h2>
            </div>
            <div *ngIf="!tabs.billing.hidden" class="tab" data-cy="tab-billing">
              <h2 [className]="tabs.billing.active? 'active': ''" (click)="tabs.billing.activate()">{{tabs.billing.title}}</h2>
            </div>
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
          </div>
        
              <ng-container
                *ngIf="tabs.licenseplates.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.licenseplates.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.licenseplates.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.licenseplates.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-asn_order_licenseplates_grid
                   *ngIf="tabs.licenseplates.tabs.grouped.active"
                  #$tabs_licenseplates_grouped
                  [order_id]="$tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_order_id"
                  [order_status_id]="$tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_order_status_id"
                  [project_id]="$tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_project_id"
                  ($refreshEvent)="refresh(false, false, '$tabs_licenseplates_grouped')">
                  </FootPrintManager-asn_order_licenseplates_grid>
                  <FootPrintManager-asn_order_receiving_tasks_grid
                   *ngIf="tabs.licenseplates.tabs.ungrouped.active"
                  #$tabs_licenseplates_ungrouped
                  [orderId]="$tabs_licenseplates_ungrouped_asn_order_receiving_tasks_grid_inParams_orderId"
                  [orderStatusId]="$tabs_licenseplates_ungrouped_asn_order_receiving_tasks_grid_inParams_orderStatusId"
                  ($refreshEvent)="refresh(false, false, '$tabs_licenseplates_ungrouped')">
                  </FootPrintManager-asn_order_receiving_tasks_grid>
              </ng-container>
              <FootPrintManager-asn_orderlines_grid *ngIf="tabs.orderlines.active"
              #$tabs_orderlines
              [orderId]="$tabs_orderlines_asn_orderlines_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_orderlines')">
              </FootPrintManager-asn_orderlines_grid>
              <ng-container
                *ngIf="tabs.billing.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.billing.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.billing.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.billing.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-accessorial_tasks_grid
                   *ngIf="tabs.billing.tabs.accessorial_tasks.active"
                  #$tabs_billing_accessorial_tasks
                  [projectId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId"
                  [entityStatusId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId"
                  [orderId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId"
                  [warehouseId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId"
                  [showAllOpCodes]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_showAllOpCodes"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_accessorial_tasks')">
                  </FootPrintManager-accessorial_tasks_grid>
                  <FootPrintManager-billing_records_grid
                   *ngIf="tabs.billing.tabs.billing_records.active"
                  #$tabs_billing_billing_records
                  [orderIds]="$tabs_billing_billing_records_billing_records_grid_inParams_orderIds"
                  [includeInvoiced]="$tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced"
                  [shipmentIds]="$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_billing_records')">
                  </FootPrintManager-billing_records_grid>
              </ng-container>
              <FootPrintManager-asn_order_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [orderId]="$tabs_tasks_asn_order_tasks_grid_inParams_orderId"
              [shipmentId]="$tabs_tasks_asn_order_tasks_grid_inParams_shipmentId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-asn_order_tasks_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>