import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { SalesOrders_license_plate_labels_by_order_reportService } from './SalesOrders.report.index';
import { SalesOrders_load_slip_reportService } from './SalesOrders.report.index';
import { SalesOrders_outbound_bill_of_lading_reportService } from './SalesOrders.report.index';
import { SalesOrders_packing_list_reportService } from './SalesOrders.report.index';
import { SalesOrders_pick_slip_by_orderId_reportService } from './SalesOrders.report.index';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public SalesOrders: SalesOrders_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _license_plate_labels_by_order_report: SalesOrders_license_plate_labels_by_order_reportService;
  public get license_plate_labels_by_order_report(): SalesOrders_license_plate_labels_by_order_reportService {
    if(!this._license_plate_labels_by_order_report) {
      this._license_plate_labels_by_order_report = this.injector.get(SalesOrders_license_plate_labels_by_order_reportService);
    }
    return this._license_plate_labels_by_order_report;
  }
  private _load_slip_report: SalesOrders_load_slip_reportService;
  public get load_slip_report(): SalesOrders_load_slip_reportService {
    if(!this._load_slip_report) {
      this._load_slip_report = this.injector.get(SalesOrders_load_slip_reportService);
    }
    return this._load_slip_report;
  }
  private _outbound_bill_of_lading_report: SalesOrders_outbound_bill_of_lading_reportService;
  public get outbound_bill_of_lading_report(): SalesOrders_outbound_bill_of_lading_reportService {
    if(!this._outbound_bill_of_lading_report) {
      this._outbound_bill_of_lading_report = this.injector.get(SalesOrders_outbound_bill_of_lading_reportService);
    }
    return this._outbound_bill_of_lading_report;
  }
  private _packing_list_report: SalesOrders_packing_list_reportService;
  public get packing_list_report(): SalesOrders_packing_list_reportService {
    if(!this._packing_list_report) {
      this._packing_list_report = this.injector.get(SalesOrders_packing_list_reportService);
    }
    return this._packing_list_report;
  }
  private _pick_slip_by_orderId_report: SalesOrders_pick_slip_by_orderId_reportService;
  public get pick_slip_by_orderId_report(): SalesOrders_pick_slip_by_orderId_reportService {
    if(!this._pick_slip_by_orderId_report) {
      this._pick_slip_by_orderId_report = this.injector.get(SalesOrders_pick_slip_by_orderId_reportService);
    }
    return this._pick_slip_by_orderId_report;
  }
}

