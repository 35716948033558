import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { LocusRobotics_ShellService } from './LocusRobotics.shell.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';
import { LocusRobotics_LocalizationService } from './LocusRobotics.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './LocusRobotics.frontend.types'
import { $frontendTypes as $types} from './LocusRobotics.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { LocusRobotics_warehouses_dd_singleComponent } from './LocusRobotics.warehouses_dd_single.component'
import { LocusRobotics_projects_dd_singleComponent } from './LocusRobotics.projects_dd_single.component'
import { LocusRobotics_bot_type_dd_singleComponent } from './LocusRobotics.bot_type_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LocusRobotics_warehouses_dd_singleComponent),
    forwardRef(() => LocusRobotics_projects_dd_singleComponent),
    forwardRef(() => LocusRobotics_bot_type_dd_singleComponent),
  ],
  selector: 'LocusRobotics-configuration_form',
  templateUrl: './LocusRobotics.configuration_form.component.html'
})
export class LocusRobotics_configuration_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { configurationType?: string } = { configurationType: null };
  //#region Inputs
  @Input('configurationType') set $inParams_configurationType(v: string) {
    this.inParams.configurationType = v;
  }
  get $inParams_configurationType(): string {
    return this.inParams.configurationType;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { warehouse?: string, locus_user?: string, locus_password?: string, project?: string, cart_dim_factor?: string, bot_type?: string, endpoint?: string } = { warehouse: null, locus_user: null, locus_password: null, project: null, cart_dim_factor: null, bot_type: null, endpoint: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ warehouse?: string, locus_user?: string, locus_password?: string, project?: string, cart_dim_factor?: string, bot_type?: string, endpoint?: string }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    locus_user_name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    locus_password: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    endpoint: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dim_factor: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    bot_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '', null)
    ),
      cancel_button: new ToolModel(new ButtonModel('cancel_button', new ButtonStyles(['destructive'], null), false, 'Cancel', '', null)
    )
  };

  fields = {
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false),
    locus_user_name: new FieldModel(new TextBoxModel(this.formGroup.controls['locus_user_name'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Locus user', false),
    locus_password: new FieldModel(new TextBoxModel(this.formGroup.controls['locus_password'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Locus password', false),
    endpoint: new FieldModel(new TextBoxModel(this.formGroup.controls['endpoint'] as DatexFormControl, null, false, 'https://111111.locusrobotics.com:50001', null)
, new ControlContainerStyles(null, null), 'Endpoint', false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', false),
    dim_factor: new FieldModel(new NumberBoxModel(this.formGroup.controls['dim_factor'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Cart dim factor', false),
    bot_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['bot_type'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Bot type', false),
  };

  fieldsets = {
  Configuration: new FieldsetModel('configurations', true, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    locus_user_name: this.fields.locus_user_name.control.valueChanges
    ,
    locus_password: this.fields.locus_password.control.valueChanges
    ,
    endpoint: this.fields.endpoint.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    dim_factor: this.fields.dim_factor.control.valueChanges
    ,
    bot_type: this.fields.bot_type.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: LocusRobotics_ShellService,
private datasources: LocusRobotics_DatasourceService,
private flows: LocusRobotics_FlowService,
private reports: LocusRobotics_ReportService,
private localization: LocusRobotics_LocalizationService,
private operations: LocusRobotics_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'New configuration';
  
    const $form = this;
    const $utils = this.utils;

    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .locus_user_name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .locus_password
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .endpoint
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .project
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dim_factor
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .bot_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: LocusRobotics_configuration_formComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  if($utils.isDefined($form.inParams.configurationType)){
      $form.title = $form.inParams.configurationType
  }
  
  if($form.inParams.configurationType.trim().toUpperCase() === 'CART DIM FACTOR CONFIGURATION'){
      $form.fields.locus_password.hidden = true; 
      $form.fields.locus_user_name.hidden = true; 
      $form.fields.endpoint.hidden = true; 
  } else if($form.inParams.configurationType.trim().toUpperCase() === 'ENDPOINT CONFIGURATION'){
      $form.fields.bot_type.hidden = true; 
      $form.fields.dim_factor.hidden = true; 
      $form.fields.project.hidden = true;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: LocusRobotics_configuration_formComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: LocusRobotics_configuration_formComponent,
  
    $shell: LocusRobotics_ShellService,
    $datasources: LocusRobotics_DatasourceService,
    $flows: LocusRobotics_FlowService,
    $reports: LocusRobotics_ReportService,
    $settings: SettingsValuesService,
    $operations: LocusRobotics_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: LocusRobotics_LocalizationService,
    $event: any
  ) {
  $form.outParams.bot_type = $form.fields.bot_type.control.value
  $form.outParams.cart_dim_factor = $form.fields.dim_factor.control.value
  $form.outParams.locus_password = $form.fields.locus_password.control.value
  $form.outParams.locus_user = $form.fields.locus_user_name.control.value
  $form.outParams.warehouse = $form.fields.warehouse.control.displayText
  $form.outParams.project = $form.fields.project.control.displayText
  $form.outParams.endpoint = $form.fields.endpoint.control.value
  $form.close();
  }
  //#endregion private flows
}
