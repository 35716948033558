import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';

interface IPurchaseOrders_inbound_unloading_tally_reportServiceInParams {
  orderId: number}

interface IPurchaseOrders_inbound_unloading_tally_reportServiceData {
  Order?: { result?: { Id?: number, AccountId?: number, BillingAddresId?: number, CreatedSysDateTime?: string, LookupCode?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, ParentOrderId?: number, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, OrderClass?: { OrderClassTypeId?: number, OrderTypeId?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { LookupCode?: string, Name?: string }, PreferredCarrier?: { Name?: string }, ParentOrder?: { LookupCode?: string } } }
  Shipment?: { result?: { OrderId?: number, ShipmentId?: number, Shipment?: { ExpectedDate?: string, ExpectedWarehouseId?: number, LookupCode?: string, SealId?: string, LoadContainer?: { LookupCode?: string } } } }
  Lines?: { result?: { OrderId?: number, LineNumber?: number, PackagedAmount?: number, ShipmentId?: number, Material?: { Description?: string, LookupCode?: string, Name?: string }, InventoryMeasurementUnit?: { Name?: string, ShortName?: string }, Lot?: { LookupCode?: string }, MaterialPackagingLookup?: { BasePackagingQuantity?: number, IsBasePackaging?: boolean, BasePackaging?: { ShortName?: string } }, Totals?: { TotalPackagedAmount?: number, TotalGrossWeight?: number, OrderId?: number, OrderLineNumber?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_inbound_unloading_tally_reportService extends ReportBaseService<IPurchaseOrders_inbound_unloading_tally_reportServiceInParams, IPurchaseOrders_inbound_unloading_tally_reportServiceData> {

  protected reportReferenceName = 'inbound_unloading_tally_report';
  protected appReferenceName = 'PurchaseOrders';

  constructor(
    utils: UtilsService,
    private datasources: PurchaseOrders_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('PurchaseOrders', 'inbound_unloading_tally_report');
  }

  override throwIfMissingRequiredInParams (inParams: IPurchaseOrders_inbound_unloading_tally_reportServiceInParams) {
  }

  protected async getData(inParams: IPurchaseOrders_inbound_unloading_tally_reportServiceInParams): Promise<IPurchaseOrders_inbound_unloading_tally_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IPurchaseOrders_inbound_unloading_tally_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IPurchaseOrders_inbound_unloading_tally_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.PurchaseOrders.ds_get_order_by_orderId.get(dsParams);
      
      data.Order = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.PurchaseOrders.ds_get_shipment_by_orderId_top1.get(dsParams);
      
      data.Shipment = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.PurchaseOrders.ds_get_orderLines_with_totals_by_orderId.get(dsParams);
      
      data.Lines = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
