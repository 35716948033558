import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class SettingsValuesService {

  private _SettingsService: any = {};
  private _isInitialized = false;

  public set SettingsService(value: any) {
    this._isInitialized = true;
    this._SettingsService = value;
  }
  public get SettingsService() {
    return this._SettingsService;
  }

  public get isInitialized() {
    return this._isInitialized;
  }

  public get DateFormat() {
    return this._SettingsService['DateFormat'];
  }
  public get DefaultTemperature() {
    return this._SettingsService['DefaultTemperature'];
  }
  public get EasyPostKey() {
    return this._SettingsService['EasyPostKey'];
  }
  public get ExcelExportLimit() {
    return this._SettingsService['ExcelExportLimit'];
  }
  public get FootPrintAPI() {
    return this._SettingsService['FootPrintAPI'];
  }
  public get integration_name() {
    return this._SettingsService['integration_name'];
  }
  public get MongoDB() {
    return this._SettingsService['MongoDB'];
  }
  public get PrintNodeKey() {
    return this._SettingsService['PrintNodeKey'];
  }
  public get TimeFormat() {
    return this._SettingsService['TimeFormat'];
  }

  public app: SettingsValuesService = this;

  public get Utilities() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Addresses() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Carriers() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Cartonization() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get LaborManagement() {
     return {
FootPrintApi : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get LoadContainers() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get PackVerification() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Replenishments() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get TemperatureReadings() {
     return {
DefaultTemperature : this.DefaultTemperature,
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get WorkOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Lots() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get ExcelLocationImport() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get SerialNumbers() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get InventoryCounts() {
     return {
DateFormat : this.DateFormat,
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get AsnOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Owners() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get WarehouseTransfers() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Notifications() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get ShippingContainers() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Inventory() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get DockAppointments() {
     return {
DateFormat : this.DateFormat,
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB,
TimeFormat : this.TimeFormat
    };
  }
  public get Reports() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Waves() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get SalesOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Materials() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get PurchaseOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Locations() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get FootPrintManager() {
     return {
DateFormat : this.DateFormat,
DefaultTemperature : this.DefaultTemperature,
EasyPostKey : this.EasyPostKey,
ExcelExportLimit : this.ExcelExportLimit,
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB,
PrintNodeKey : this.PrintNodeKey,
TimeFormat : this.TimeFormat
    };
  }
  public get LocusRobotics() {
     return {
DateFormat : this.DateFormat,
FootPrintAPI : this.FootPrintAPI,
integration_name : this.integration_name,
MongoDB : this.MongoDB,
PrintNodeKey : this.PrintNodeKey
    };
  }
  public get BarcodeParsing() {
     return {
FootPrintAPI : this.FootPrintAPI
    };
  }
  public get Attachments() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get EntityImport() {
     return {
DateFormat : this.DateFormat,
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Equipment() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get ExcelMaterialImport() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get ExcelProjectImport() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Inspections() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Instructions() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get InventoryTransfers() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get PrintNode() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB,
PrintNodeKey : this.PrintNodeKey
    };
  }
  public get PalletTransactions() {
     return {
DateFormat : this.DateFormat,
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB,
TimeFormat : this.TimeFormat
    };
  }
  public get Returns() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Surveys() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get TransloadOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Discussions() {
     return {
FootprintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get ExcelOrderImport() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get BarTender() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Manifesting() {
     return {
EasyPostKey : this.EasyPostKey,
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB,
PrintNodeKey : this.PrintNodeKey
    };
  }
  public get ExcelInventoryImport() {
     return {
DateFormat : this.DateFormat,
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Invoices() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Catalogs() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get EasyPost() {
     return {
EasyPostKey : this.EasyPostKey,
FootPrintAPI : this.FootPrintAPI
    };
  }
  public get ShipTheory() {
     return {
FootPrintAPI : this.FootPrintAPI
    };
  }
}
