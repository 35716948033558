<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes"
          [matTooltip]="row.cells.description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['description_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.description.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.description.editControl.placeholder}}"
            [ngStyle]="row.cells.description.editControl.styles.style"
            [ngClass]="row.cells.description.editControl.styles.classes"
            [matTooltip]="row.cells.description.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="default_warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.default_warehouse.displayControl.styles.style"
          [ngClass]="row.cells.default_warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.default_warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.default_warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-custom_warehouses_dd_single 
        data-cy="selector"
        [type]="row.cells.default_warehouse.editControl.type"
        [formControl]="row.formGroup.controls['default_warehouse_edit']"
        (displayTextChange)="row.cells.default_warehouse.editControl.displayText=$event"
        [placeholder]="row.cells.default_warehouse.editControl.placeholder"
        [styles]="row.cells.default_warehouse.editControl.styles"
        [tooltip]="row.cells.default_warehouse.editControl.tooltip"
    >
    </app-custom_warehouses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="receiving_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.receiving_status.displayControl.styles.style"
          [ngClass]="row.cells.receiving_status.displayControl.styles.classes"
          [matTooltip]="row.cells.receiving_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.receiving_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-custom_udf_receiving_status_single 
        data-cy="selector"
        [type]="row.cells.receiving_status.editControl.type"
        [formControl]="row.formGroup.controls['receiving_status_edit']"
        (displayTextChange)="row.cells.receiving_status.editControl.displayText=$event"
        [placeholder]="row.cells.receiving_status.editControl.placeholder"
        [styles]="row.cells.receiving_status.editControl.styles"
        [tooltip]="row.cells.receiving_status.editControl.tooltip"
    >
    </app-custom_udf_receiving_status_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dispostion">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.dispostion.displayControl.styles.style"
          [ngClass]="row.cells.dispostion.displayControl.styles.classes"
          [matTooltip]="row.cells.dispostion.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.dispostion.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-custom_material_udf_disposition_dd_single 
        data-cy="selector"
        [type]="row.cells.dispostion.editControl.type"
        [formControl]="row.formGroup.controls['dispostion_edit']"
        (displayTextChange)="row.cells.dispostion.editControl.displayText=$event"
        [placeholder]="row.cells.dispostion.editControl.placeholder"
        [styles]="row.cells.dispostion.editControl.styles"
        [tooltip]="row.cells.dispostion.editControl.tooltip"
    >
    </app-custom_material_udf_disposition_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="return_short_dated_disposition">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.return_short_dated_disposition.displayControl.styles.style"
          [ngClass]="row.cells.return_short_dated_disposition.displayControl.styles.classes"
          [matTooltip]="row.cells.return_short_dated_disposition.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.return_short_dated_disposition.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-custom_material_udf_disposition_dd_single 
        data-cy="selector"
        [type]="row.cells.return_short_dated_disposition.editControl.type"
        [formControl]="row.formGroup.controls['return_short_dated_disposition_edit']"
        (displayTextChange)="row.cells.return_short_dated_disposition.editControl.displayText=$event"
        [placeholder]="row.cells.return_short_dated_disposition.editControl.placeholder"
        [styles]="row.cells.return_short_dated_disposition.editControl.styles"
        [tooltip]="row.cells.return_short_dated_disposition.editControl.tooltip"
    >
    </app-custom_material_udf_disposition_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="arcos_eligible">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['arcos_eligible_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.arcos_eligible.displayControl.styles.style"
                  [ngClass]="row.cells.arcos_eligible.displayControl.styles.classes"
                  [matTooltip]="row.cells.arcos_eligible.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.arcos_eligible.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['arcos_eligible_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.arcos_eligible.editControl.styles.style"
                  [ngClass]="row.cells.arcos_eligible.editControl.styles.classes"
                  [matTooltip]="row.cells.arcos_eligible.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.arcos_eligible.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="harmonized_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.harmonized_code.displayControl.styles.style"
          [ngClass]="row.cells.harmonized_code.displayControl.styles.classes"
          [matTooltip]="row.cells.harmonized_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.harmonized_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['harmonized_code_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.harmonized_code.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.harmonized_code.editControl.placeholder}}"
            [ngStyle]="row.cells.harmonized_code.editControl.styles.style"
            [ngClass]="row.cells.harmonized_code.editControl.styles.classes"
            [matTooltip]="row.cells.harmonized_code.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="drug_schedule">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.drug_schedule.displayControl.styles.style"
          [ngClass]="row.cells.drug_schedule.displayControl.styles.classes"
          [matTooltip]="row.cells.drug_schedule.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.drug_schedule.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['drug_schedule_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.drug_schedule.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.drug_schedule.editControl.placeholder}}"
            [ngStyle]="row.cells.drug_schedule.editControl.styles.style"
            [ngClass]="row.cells.drug_schedule.editControl.styles.classes"
            [matTooltip]="row.cells.drug_schedule.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="hazardous_material">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['hazardous_material_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.hazardous_material.displayControl.styles.style"
                  [ngClass]="row.cells.hazardous_material.displayControl.styles.classes"
                  [matTooltip]="row.cells.hazardous_material.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.hazardous_material.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['hazardous_material_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.hazardous_material.editControl.styles.style"
                  [ngClass]="row.cells.hazardous_material.editControl.styles.classes"
                  [matTooltip]="row.cells.hazardous_material.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.hazardous_material.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="hazardous_class">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.hazardous_class.displayControl.styles.style"
          [ngClass]="row.cells.hazardous_class.displayControl.styles.classes"
          [matTooltip]="row.cells.hazardous_class.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.hazardous_class.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['hazardous_class_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.hazardous_class.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.hazardous_class.editControl.placeholder}}"
            [ngStyle]="row.cells.hazardous_class.editControl.styles.style"
            [ngClass]="row.cells.hazardous_class.editControl.styles.classes"
            [matTooltip]="row.cells.hazardous_class.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="mass_required">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['mass_required_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.mass_required.displayControl.styles.style"
                  [ngClass]="row.cells.mass_required.displayControl.styles.classes"
                  [matTooltip]="row.cells.mass_required.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.mass_required.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['mass_required_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.mass_required.editControl.styles.style"
                  [ngClass]="row.cells.mass_required.editControl.styles.classes"
                  [matTooltip]="row.cells.mass_required.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.mass_required.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="critical_part">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['critical_part_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.critical_part.displayControl.styles.style"
                  [ngClass]="row.cells.critical_part.displayControl.styles.classes"
                  [matTooltip]="row.cells.critical_part.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.critical_part.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['critical_part_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.critical_part.editControl.styles.style"
                  [ngClass]="row.cells.critical_part.editControl.styles.classes"
                  [matTooltip]="row.cells.critical_part.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.critical_part.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="gtin">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.gtin.displayControl.styles.style"
          [ngClass]="row.cells.gtin.displayControl.styles.classes"
          [matTooltip]="row.cells.gtin.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.gtin.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['gtin_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.gtin.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.gtin.editControl.placeholder}}"
            [ngStyle]="row.cells.gtin.editControl.styles.style"
            [ngClass]="row.cells.gtin.editControl.styles.classes"
            [matTooltip]="row.cells.gtin.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="hazmat_proper_shipping">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.hazmat_proper_shipping.displayControl.styles.style"
          [ngClass]="row.cells.hazmat_proper_shipping.displayControl.styles.classes"
          [matTooltip]="row.cells.hazmat_proper_shipping.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.hazmat_proper_shipping.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['hazmat_proper_shipping_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.hazmat_proper_shipping.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.hazmat_proper_shipping.editControl.placeholder}}"
            [ngStyle]="row.cells.hazmat_proper_shipping.editControl.styles.style"
            [ngClass]="row.cells.hazmat_proper_shipping.editControl.styles.classes"
            [matTooltip]="row.cells.hazmat_proper_shipping.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="hazmat_UN_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.hazmat_UN_number.displayControl.styles.style"
          [ngClass]="row.cells.hazmat_UN_number.displayControl.styles.classes"
          [matTooltip]="row.cells.hazmat_UN_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.hazmat_UN_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['hazmat_UN_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.hazmat_UN_number.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.hazmat_UN_number.editControl.placeholder}}"
            [ngStyle]="row.cells.hazmat_UN_number.editControl.styles.style"
            [ngClass]="row.cells.hazmat_UN_number.editControl.styles.classes"
            [matTooltip]="row.cells.hazmat_UN_number.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="hazmat_package_group">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.hazmat_package_group.displayControl.styles.style"
          [ngClass]="row.cells.hazmat_package_group.displayControl.styles.classes"
          [matTooltip]="row.cells.hazmat_package_group.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.hazmat_package_group.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['hazmat_package_group_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.hazmat_package_group.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.hazmat_package_group.editControl.placeholder}}"
            [ngStyle]="row.cells.hazmat_package_group.editControl.styles.style"
            [ngClass]="row.cells.hazmat_package_group.editControl.styles.classes"
            [matTooltip]="row.cells.hazmat_package_group.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="country_of_origin">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.country_of_origin.displayControl.styles.style"
          [ngClass]="row.cells.country_of_origin.displayControl.styles.classes"
          [matTooltip]="row.cells.country_of_origin.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.country_of_origin.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['country_of_origin_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.country_of_origin.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.country_of_origin.editControl.placeholder}}"
            [ngStyle]="row.cells.country_of_origin.editControl.styles.style"
            [ngClass]="row.cells.country_of_origin.editControl.styles.classes"
            [matTooltip]="row.cells.country_of_origin.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
