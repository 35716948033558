import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_vector_labelServiceInParams {
  jobId?: number, licensePlateId?: number}

interface IReports_custom_vector_labelServiceData {
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_vector_labelService extends ReportBaseService<IReports_custom_vector_labelServiceInParams, IReports_custom_vector_labelServiceData> {

  protected reportReferenceName = 'custom_vector_label';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('Reports', 'custom_vector_label');
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_vector_labelServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_vector_labelServiceInParams): Promise<IReports_custom_vector_labelServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_vector_labelServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_vector_labelServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];


    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
