import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-vendorlot_creation_form',
  templateUrl: './FootPrintManager.vendorlot_creation_form.component.html'
})
export class FootPrintManager_vendorlot_creation_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { materialId: number, vendorLookupcode?: string } = { materialId: null, vendorLookupcode: null };
  //#region Inputs
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('vendorLookupcode') set $inParams_vendorLookupcode(v: string) {
    this.inParams.vendorLookupcode = v;
  }
  get $inParams_vendorLookupcode(): string {
    return this.inParams.vendorLookupcode;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { vendorLotId?: number, confirm?: boolean } = { vendorLotId: null, confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { nextId?: number, isExpirationManuallySet?: boolean, isManufactureManuallySet?: boolean, shelfLife?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ vendorLotId?: number, confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    vendor_lot: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    vat: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    manufacture_date: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    expiration_date: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '', null)
    )
  };

  fields = {
    vendor_lot: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_lot'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Vendor Lot', true),
    vat: new FieldModel(new TextBoxModel(this.formGroup.controls['vat'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Mass per UOM', false),
    manufacture_date: new FieldModel(new DateBoxModel(this.formGroup.controls['manufacture_date'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Manufacture Date', true),
    expiration_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expiration_date'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Expiration Date', true),
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Description', false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Create Vendor Lot', true, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    vendor_lot: this.fields.vendor_lot.control.valueChanges
    ,
    vat: this.fields.vat.control.valueChanges
    ,
    manufacture_date: this.fields.manufacture_date.control.valueChanges
    ,
    expiration_date: this.fields.expiration_date.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Create Vendor Lot';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.vendor_lot.control as TextBoxModel).reset($form.inParams.vendorLookupcode);
    
    (this.fields.manufacture_date.control as DateBoxModel).reset($utils.date.now());
    (this.fields.expiration_date.control as DateBoxModel).reset($utils.date.now());
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .vendor_lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vat
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .manufacture_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_manufacture_date_changed();
      });
    this.$formGroupFieldValidationObservables
      .expiration_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_expiration_date_changed();
      });
    this.$formGroupFieldValidationObservables
      .description
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_vendorlot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.outParams.vendorLotId = null;
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_vendorlot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  
  try {
      /*** VALIDATIONS ***/
      if (!$utils.isDefinedTrimmed($form.fields.vendor_lot.control.value)) {
          $form.toolbar.confirm.control.readOnly = false;
          let message = 'Please enter a valid value for vendor lot lookup code.';
  
          throw new Error(message);
      }
      if (!$utils.isDefined($form.fields.manufacture_date.control.value)) {
          $form.toolbar.confirm.control.readOnly = false;
          let message = 'Please enter a value for manufacture date.';
          //$shell.Lots.openErrorDialog('Vendor Lot Creation Error', message);
          throw new Error(message);
      }
      if (!$utils.isDefined($form.fields.expiration_date.control.value)) {
          $form.toolbar.confirm.control.readOnly = false;
          let message = 'Please enter a value for expiration date.';
          //$shell.Lots.openErrorDialog('Vendor Lot Creation Error', message);
          throw new Error(message);
      }
  
      // MassPerUOM
      if ($utils.isDefined($form.fields.vat.control.value)) {
          if (isNaN($form.fields.vat.control.value)) {
              throw new Error("'Mass per UOM' must be a numeric value.");
          }
          if ($form.fields.vat.control.value <= 0) {
              throw new Error("'Mass per UOM' must be greater than zero.");
          }
      }
  
      const materialId = $form.inParams.materialId
  
      var vendorLotLookup = $form.fields.vendor_lot.control.value.trim();
  
      // Check if vendor lot exists
      var vendorLotId;
  
      const vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_lookupcode_and_materialId.get({
          materialId: $form.inParams.materialId,
          vendorLookupcode: vendorLotLookup
      })).result;
      if ($utils.isDefined(vendorLot)) {
          $form.toolbar.confirm.control.readOnly = false;
          throw new Error('Vendor Lot already exists for selected material!');
      }
      else {
          try {
  
              /*** Create Vendorlot ***/
              vendorLotId = (await $flows.Lots.create_vendor_lot({
                  materialId: materialId,
                  lookupCode: vendorLotLookup,
                  manufactureDate: $form.fields.manufacture_date.control.value,
                  expirationDate: $form.fields.expiration_date.control.value
              })).vendorLotId
          }
          catch (error) {
              $shell.Lots.showErrorDetails('Save', 'Error on creating vendor lot.', error);
              throw error; // to prevent displayMode 
          }
  
          if ($utils.isDefined(vendorLotId)) {
              // Update other vendorlot properties
              let payload: any = {};
  
              // Vat
              if ($utils.isDefined($form.fields.vat.control.value)) {
                  payload.Vat = $form.fields.vat.control.value;
              }
  
              // Notes
              if ($utils.isDefined($form.fields.notes.control.value)) {
                  payload.Notes = $form.fields.notes.control.value;
              }
  
              // Description
              if ($utils.isDefined($form.fields.description.control.value)) {
                  payload.Description = $form.fields.description.control.value;
              }
  
              if (Object.keys(payload).length > 0) {
                  try {
                      await $flows.Utilities.crud_update_flow({ entitySet: 'VendorLots', id: vendorLotId, entity: payload });
                  }
                  catch (error) {
                      $shell.Lots.showErrorDetails('Save', 'Error on updating newly created vendor lot.', error);
                      throw error; // to prevent displayMode 
                  }
              }
          }
  
          $form.outParams.vendorLotId = vendorLotId;
          $form.outParams.confirm = true;
  
  
          $form.close();
  
  
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.openErrorDialog('Error creating new vendor lot', error.message);
  }
  
  $form.toolbar.confirm.control.readOnly = false;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_vendorlot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.vars.isExpirationManuallySet = false;
  $form.vars.isManufactureManuallySet = false;
  
  
  $form.outParams.confirm = false;
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: "VendorLot" })).nextId;
  
  // Get Material ShelfLifeSpan
  $form.vars.shelfLife = (await $datasources.Lots.ds_get_material_by_materialId.get({ materialId: $form.inParams.materialId })).result?.ShelfLifeSpan ?? 0;
  
  // If the input vendor lot lookupcode is not provided default to the next Id
  let result = (await $flows.Lots.recommend_lot_creation({
      vendorLotId: nextId,
      vendorLotLookupCode: $form.inParams.vendorLookupcode,
      recommendVendorLotOnly: true,
      materialId: $form.inParams.materialId,
      optimalInputs: {
          shelfLifeSpan: $form.vars.shelfLife
      }
  }));
  
  
  
  let materialUdfs = (await $datasources.custom_ds_materials_udf_grid.get({ materialIds: [$form.inParams.materialId] })).result[0];
  
  
  
  $form.fields.manufacture_date.control.value = $utils.isDefined(result.manufactureDate) ? $utils.date.format(result.manufactureDate) : $utils.date.now();
  $form.fields.expiration_date.control.value = $utils.isDefined(result.expirationDate) ? $utils.date.format(result.expirationDate) : $utils.date.now();
  
  
  // Hide Fields
  $form.fields.manufacture_date.hidden = true;
  $form.fields.description.hidden = true;
  $form.fields.manufacture_date.hidden = true;
  
  // MassRequired
  if ($utils.isDefined(materialUdfs?.MassRequired) && materialUdfs.MassRequired) {
      if ($utils.isDefined(result.vat)) {
          $form.fields.vat.control.value = result.vat;
      }
  }
  else {
      $form.fields.vat.hidden = true;
  }
  }
  on_manufacture_date_changed(event = null) {
    return this.on_manufacture_date_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manufacture_date_changedInternal(
    $form: FootPrintManager_vendorlot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // ManufactureDate Changed
  if ($form.fields.manufacture_date.control.isChanged) {
      $form.vars.isManufactureManuallySet = true;
  
      // Auto set ExpirationDate to ManufactureDate + Material.ShelfLife (if Expiration date has not manually been set)
      if (!$form.vars.isExpirationManuallySet) {
          let newDate = $utils.date.add($form.vars.shelfLife, 'day', $form.fields.manufacture_date.control.value);
  
          $form.fields.expiration_date.control.value = $utils.date.format(newDate);
      }
  }
  
  }
  on_expiration_date_changed(event = null) {
    return this.on_expiration_date_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_expiration_date_changedInternal(
    $form: FootPrintManager_vendorlot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  // ExpirationDate Changed
  if ($form.fields.expiration_date.control.isChanged) {
      $form.vars.isExpirationManuallySet = true;
  
      // Auto set ManufactureDate to ExpirationDate - Material.ShelfLife (if Manufacture date has not manually been set)
      if (!$form.vars.isManufactureManuallySet) {
          let newDate = $utils.date.subtract($form.vars.shelfLife, 'day', $form.fields.expiration_date.control.value);
          
          $form.fields.manufacture_date.control.value = $utils.date.format(newDate);
      }
  }
  }
  //#endregion private flows
}
