import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './LocusRobotics.frontend.types'

@Injectable({ providedIn: 'root' })
export class LocusRobotics_split_order_job_by_weightService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { orderJobTasks: $frontendTypes.LocusRobotics.OrderJobTask[], weightCapacity?: number, dimFactor?: number }): Promise<{ groupedJobTasks?: { jobNumber: number, orderJobTasks: $frontendTypes.LocusRobotics.OrderJobTask[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderJobTasks)) {
      missingRequiredInParams.push('\'orderJobTasks\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LocusRobotics/functions/split_order_job_by_weight`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

