import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';

interface ILocusRobotics_outbound_labelsServiceInParams {
  shippingContainerId: number}

interface ILocusRobotics_outbound_labelsServiceData {
  label?: { result?: { Id?: number, ActualTargetShippingContainerId?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, ExpectedTargetShippingContainerId?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, OrderId?: number, ProjectId?: number, ShipmentId?: number, StartDateTime?: string, WarehouseId?: number, Order?: { Id?: number, AccountId?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, Account?: { Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { Id?: number, City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] } }, Shipment?: { Id?: number, AccountId?: number, LookupCode?: string, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Id?: number, Name?: string, ShortName?: string }, ShippingContainers?: { Id?: number, LookupCode?: string, ContainerType?: { Description?: string, Name?: string } }[] }, Project?: { Name?: string }, ActualTargetShippingContainer?: { ContainerTypeId?: number, LookupCode?: string, ContainerType?: { Description?: string, Name?: string } }, ExpectedTargetShippingContainer?: { ContainerTypeId?: number, LookupCode?: string, ContainerType?: { Description?: string, Name?: string } }, ship_to?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number }, ship2?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryTelephone?: string, State?: string, OAddresses?: string }, first7?: string, ord4char?: string } }
}

@Injectable({ providedIn: 'root' })
export class LocusRobotics_outbound_labelsService extends ReportBaseService<ILocusRobotics_outbound_labelsServiceInParams, ILocusRobotics_outbound_labelsServiceData> {

  protected reportReferenceName = 'outbound_labels';
  protected appReferenceName = 'LocusRobotics';

  constructor(
    utils: UtilsService,
    private datasources: LocusRobotics_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('LocusRobotics', 'outbound_labels');
  }

  override throwIfMissingRequiredInParams (inParams: ILocusRobotics_outbound_labelsServiceInParams) {
  }

  protected async getData(inParams: ILocusRobotics_outbound_labelsServiceInParams): Promise<ILocusRobotics_outbound_labelsServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: ILocusRobotics_outbound_labelsServiceInParams } = {
      inParams: inParams
    };

    const data: ILocusRobotics_outbound_labelsServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        actualTargetShippingContainerId:  $report.inParams.shippingContainerId ,
        taskIds:  null ,
        fullTextSearch:  null ,
        chainHead:  null 
      };
      
      const dsData = await this.datasources.LocusRobotics.ds_get_shipping_containers_tasks.get(dsParams);
      
      data.label = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
