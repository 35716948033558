import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Reports_HomeComponent } from './Reports.Home.component';
import { Reports_custom_appointment_badge_reportComponent } from './Reports.custom_appointment_badge_report.component';
import { Reports_custom_cycle_count_reportComponent } from './Reports.custom_cycle_count_report.component';
import { Reports_custom_label_cencoraComponent } from './Reports.custom_label_cencora.component';
import { Reports_custom_label_eversanaComponent } from './Reports.custom_label_eversana.component';
import { Reports_custom_label_mckcvsComponent } from './Reports.custom_label_mckcvs.component';
import { Reports_custom_label_walmartComponent } from './Reports.custom_label_walmart.component';
import { Reports_custom_license_plate_report_by_serialIdComponent } from './Reports.custom_license_plate_report_by_serialId.component';
import { Reports_custom_outbound_labels_reportComponent } from './Reports.custom_outbound_labels_report.component';
import { Reports_custom_packing_list_reportComponent } from './Reports.custom_packing_list_report.component';
import { Reports_custom_packing_slip_reportComponent } from './Reports.custom_packing_slip_report.component';
import { Reports_custom_receiving_reportComponent } from './Reports.custom_receiving_report.component';
import { Reports_custom_transaction_reportComponent } from './Reports.custom_transaction_report.component';
import { Reports_custom_vector_labelComponent } from './Reports.custom_vector_label.component';


@Injectable({ providedIn: 'root' })
export class Reports_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public Reports: Reports_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openHome(replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'Home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Reports_Home',
        component: Reports_HomeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openHomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'Home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Reports_HomeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public opencustom_appointment_badge_report(inParams:{ dockAppointmentId: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_appointment_badge_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_appointment_badge_report',
        referenceName: 'Reports_custom_appointment_badge_report',
        component: Reports_custom_appointment_badge_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_appointment_badge_reportDialog(
    inParams:{ dockAppointmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_appointment_badge_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_appointment_badge_reportComponent,
      'custom_appointment_badge_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_cycle_count_report(inParams:{ cycle_count_id: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Cycle Count Report',
        referenceName: 'Reports_custom_cycle_count_report',
        component: Reports_custom_cycle_count_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_cycle_count_reportDialog(
    inParams:{ cycle_count_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_cycle_count_reportComponent,
      'Cycle Count Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_label_cencora(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_label_cencora');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_label_cencora',
        referenceName: 'Reports_custom_label_cencora',
        component: Reports_custom_label_cencoraComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_label_cencoraDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_label_cencora');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_label_cencoraComponent,
      'custom_label_cencora',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_label_eversana(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_label_eversana');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_label_eversana',
        referenceName: 'Reports_custom_label_eversana',
        component: Reports_custom_label_eversanaComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_label_eversanaDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_label_eversana');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_label_eversanaComponent,
      'custom_label_eversana',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_label_mckcvs(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_label_mckcvs');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_label_mckcvs',
        referenceName: 'Reports_custom_label_mckcvs',
        component: Reports_custom_label_mckcvsComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_label_mckcvsDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_label_mckcvs');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_label_mckcvsComponent,
      'custom_label_mckcvs',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_label_walmart(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_label_walmart');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_label_walmart',
        referenceName: 'Reports_custom_label_walmart',
        component: Reports_custom_label_walmartComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_label_walmartDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_label_walmart');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_label_walmartComponent,
      'custom_label_walmart',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_license_plate_report_by_serialId(inParams:{ serialNumberId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_license_plate_report_by_serialId');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_license_plate_report_by_serialId',
        referenceName: 'Reports_custom_license_plate_report_by_serialId',
        component: Reports_custom_license_plate_report_by_serialIdComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_license_plate_report_by_serialIdDialog(
    inParams:{ serialNumberId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_license_plate_report_by_serialId');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_license_plate_report_by_serialIdComponent,
      'custom_license_plate_report_by_serialId',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_labels_report(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_outbound_labels_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_outbound_labels_report',
        referenceName: 'Reports_custom_outbound_labels_report',
        component: Reports_custom_outbound_labels_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_outbound_labels_reportDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_outbound_labels_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_outbound_labels_reportComponent,
      'custom_outbound_labels_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_packing_list_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_packing_list_report',
        referenceName: 'Reports_custom_packing_list_report',
        component: Reports_custom_packing_list_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_packing_list_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_packing_list_reportComponent,
      'custom_packing_list_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_packing_slip_report(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_packing_slip_report',
        referenceName: 'Reports_custom_packing_slip_report',
        component: Reports_custom_packing_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_packing_slip_reportDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_packing_slip_reportComponent,
      'custom_packing_slip_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Receiving Report',
        referenceName: 'Reports_custom_receiving_report',
        component: Reports_custom_receiving_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_reportComponent,
      'Receiving Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_transaction_report(inParams:{ notes: string }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_transaction_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_transaction_report',
        referenceName: 'Reports_custom_transaction_report',
        component: Reports_custom_transaction_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_transaction_reportDialog(
    inParams:{ notes: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_transaction_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_transaction_reportComponent,
      'custom_transaction_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_vector_label(inParams:{ jobId?: number, licensePlateId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_vector_label');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_vector_label',
        referenceName: 'Reports_custom_vector_label',
        component: Reports_custom_vector_labelComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_vector_labelDialog(
    inParams:{ jobId?: number, licensePlateId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_vector_label');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_vector_labelComponent,
      'custom_vector_label',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Reports_Home') {
      this.logger.log('Reports', 'Home');
      const title = 'Home';
      const component = Reports_HomeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_appointment_badge_report') {
      this.logger.log('Reports', 'custom_appointment_badge_report');
      const title = 'custom_appointment_badge_report';
      const component = Reports_custom_appointment_badge_reportComponent;
      const inParams:{ dockAppointmentId: number } = { dockAppointmentId: null };
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_cycle_count_report') {
      const title = 'Cycle Count Report';
      const component = Reports_custom_cycle_count_reportComponent;
      const inParams:{ cycle_count_id: number } = { cycle_count_id: null };
      if (!isNil(params.get('cycle_count_id'))) {
        const paramValueString = params.get('cycle_count_id');
        inParams.cycle_count_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_label_cencora') {
      this.logger.log('Reports', 'custom_label_cencora');
      const title = 'custom_label_cencora';
      const component = Reports_custom_label_cencoraComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_label_eversana') {
      this.logger.log('Reports', 'custom_label_eversana');
      const title = 'custom_label_eversana';
      const component = Reports_custom_label_eversanaComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_label_mckcvs') {
      this.logger.log('Reports', 'custom_label_mckcvs');
      const title = 'custom_label_mckcvs';
      const component = Reports_custom_label_mckcvsComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_label_walmart') {
      this.logger.log('Reports', 'custom_label_walmart');
      const title = 'custom_label_walmart';
      const component = Reports_custom_label_walmartComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_license_plate_report_by_serialId') {
      this.logger.log('Reports', 'custom_license_plate_report_by_serialId');
      const title = 'custom_license_plate_report_by_serialId';
      const component = Reports_custom_license_plate_report_by_serialIdComponent;
      const inParams:{ serialNumberId?: number } = { serialNumberId: null };
      if (!isNil(params.get('serialNumberId'))) {
        const paramValueString = params.get('serialNumberId');
        inParams.serialNumberId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_outbound_labels_report') {
      this.logger.log('Reports', 'custom_outbound_labels_report');
      const title = 'custom_outbound_labels_report';
      const component = Reports_custom_outbound_labels_reportComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_packing_list_report') {
      const title = 'custom_packing_list_report';
      const component = Reports_custom_packing_list_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_packing_slip_report') {
      const title = 'custom_packing_slip_report';
      const component = Reports_custom_packing_slip_reportComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_report') {
      const title = 'Receiving Report';
      const component = Reports_custom_receiving_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_transaction_report') {
      this.logger.log('Reports', 'custom_transaction_report');
      const title = 'custom_transaction_report';
      const component = Reports_custom_transaction_reportComponent;
      const inParams:{ notes: string } = { notes: null };
      if (!isNil(params.get('notes'))) {
        const paramValueString = params.get('notes');
        // TODO: date
        inParams.notes = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_vector_label') {
      this.logger.log('Reports', 'custom_vector_label');
      const title = 'custom_vector_label';
      const component = Reports_custom_vector_labelComponent;
      const inParams:{ jobId?: number, licensePlateId?: number } = { jobId: null, licensePlateId: null };
      if (!isNil(params.get('jobId'))) {
        const paramValueString = params.get('jobId');
        inParams.jobId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
