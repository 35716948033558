<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="release_count" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_release_count_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="complete_count" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_complete_count_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="duplicate_count" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_duplicate_count_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="hold_count" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_hold_count_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel_count" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_count_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="delete_count" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_delete_count_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="print" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_print_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Header</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container full {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="status"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.status.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.status.control.placeholder}}"
                                [ngStyle]="fields.status.control.styles.style"
                                [ngClass]="fields.status.control.styles.classes"
                                [matTooltip]="fields.status.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-event_result" *ngIf="!fields.event_result.hidden" 
                            class="field-container full {{fields.event_result.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.event_result.styles.style"
                            [ngClass]="fields.event_result.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.event_result.label + (fields.event_result.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.event_result.label}}<span *ngIf="fields.event_result.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="event_result"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.event_result.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.event_result.control.placeholder}}"
                                [ngStyle]="fields.event_result.control.styles.style"
                                [ngClass]="fields.event_result.control.styles.classes"
                                [matTooltip]="fields.event_result.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.event_result.invalid">
                          <ng-container *ngFor="let error of fields.event_result.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <InventoryCounts-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </InventoryCounts-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-count_type" *ngIf="!fields.count_type.hidden" 
                            class="field-container standard {{fields.count_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.count_type.styles.style"
                            [ngClass]="fields.count_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.count_type.label + (fields.count_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.count_type.label}}<span *ngIf="fields.count_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-custom_count_types_dd_single 
                            data-cy="selector"
                            [type]="fields.count_type.control.type"
                            formControlName="count_type"
                            (displayTextChange)="fields.count_type.control.displayText=$event"
                            [placeholder]="fields.count_type.control.placeholder"
                            [styles]="fields.count_type.control.styles"
                            [tooltip]="fields.count_type.control.tooltip"
                        >
                        </app-custom_count_types_dd_single>
                        <ng-container *ngIf="fields.count_type.invalid">
                          <ng-container *ngFor="let error of fields.count_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"
                                [matTooltip]="fields.description.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Locations</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-use_location_filter" *ngIf="!fields.use_location_filter.hidden" 
                            class="field-container standard {{fields.use_location_filter.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.use_location_filter.styles.style"
                            [ngClass]="fields.use_location_filter.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.use_location_filter.label + (fields.use_location_filter.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.use_location_filter.label}}<span *ngIf="fields.use_location_filter.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="use_location_filter"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.use_location_filter.control.styles.style"
                                      [ngClass]="fields.use_location_filter.control.styles.classes"
                                      [matTooltip]="fields.use_location_filter.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.use_location_filter.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.use_location_filter.invalid">
                          <ng-container *ngFor="let error of fields.use_location_filter.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-select_locations" *ngIf="!fields.select_locations.hidden" 
                            class="field-container full {{fields.select_locations.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.select_locations.styles.style"
                            [ngClass]="fields.select_locations.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.select_locations.label + (fields.select_locations.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.select_locations.label}}<span *ngIf="fields.select_locations.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.select_locations.control.readOnly"
                                [ngStyle]="fields.select_locations.control.styles.style"
                                [ngClass]="fields.select_locations.control.styles.classes"
                                [matTooltip]="fields.select_locations.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_select_locations_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.select_locations.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.select_locations.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.select_locations.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.select_locations.invalid">
                          <ng-container *ngFor="let error of fields.select_locations.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_only_empty_locations" *ngIf="!fields.is_only_empty_locations.hidden" 
                            class="field-container standard {{fields.is_only_empty_locations.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_only_empty_locations.styles.style"
                            [ngClass]="fields.is_only_empty_locations.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_only_empty_locations.label + (fields.is_only_empty_locations.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_only_empty_locations.label}}<span *ngIf="fields.is_only_empty_locations.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_only_empty_locations"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_only_empty_locations.control.styles.style"
                                      [ngClass]="fields.is_only_empty_locations.control.styles.classes"
                                      [matTooltip]="fields.is_only_empty_locations.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_only_empty_locations.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_only_empty_locations.invalid">
                          <ng-container *ngFor="let error of fields.is_only_empty_locations.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_skip_hard_allocation_locations" *ngIf="!fields.is_skip_hard_allocation_locations.hidden" 
                            class="field-container standard {{fields.is_skip_hard_allocation_locations.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_skip_hard_allocation_locations.styles.style"
                            [ngClass]="fields.is_skip_hard_allocation_locations.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_skip_hard_allocation_locations.label + (fields.is_skip_hard_allocation_locations.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_skip_hard_allocation_locations.label}}<span *ngIf="fields.is_skip_hard_allocation_locations.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_skip_hard_allocation_locations"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_skip_hard_allocation_locations.control.styles.style"
                                      [ngClass]="fields.is_skip_hard_allocation_locations.control.styles.classes"
                                      [matTooltip]="fields.is_skip_hard_allocation_locations.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_skip_hard_allocation_locations.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_skip_hard_allocation_locations.invalid">
                          <ng-container *ngFor="let error of fields.is_skip_hard_allocation_locations.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_include_empty_locations" *ngIf="!fields.is_include_empty_locations.hidden" 
                            class="field-container standard {{fields.is_include_empty_locations.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_include_empty_locations.styles.style"
                            [ngClass]="fields.is_include_empty_locations.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_include_empty_locations.label + (fields.is_include_empty_locations.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_include_empty_locations.label}}<span *ngIf="fields.is_include_empty_locations.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_include_empty_locations"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_include_empty_locations.control.styles.style"
                                      [ngClass]="fields.is_include_empty_locations.control.styles.classes"
                                      [matTooltip]="fields.is_include_empty_locations.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_include_empty_locations.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_include_empty_locations.invalid">
                          <ng-container *ngFor="let error of fields.is_include_empty_locations.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup3"
                     *ngIf="!fieldsets.newGroup3.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text">Materials</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-use_material_filter" *ngIf="!fields.use_material_filter.hidden" 
                            class="field-container standard {{fields.use_material_filter.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.use_material_filter.styles.style"
                            [ngClass]="fields.use_material_filter.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.use_material_filter.label + (fields.use_material_filter.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.use_material_filter.label}}<span *ngIf="fields.use_material_filter.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="use_material_filter"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.use_material_filter.control.styles.style"
                                      [ngClass]="fields.use_material_filter.control.styles.classes"
                                      [matTooltip]="fields.use_material_filter.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.use_material_filter.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.use_material_filter.invalid">
                          <ng-container *ngFor="let error of fields.use_material_filter.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material_filter_option" *ngIf="!fields.material_filter_option.hidden" 
                            class="field-container full {{fields.material_filter_option.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_filter_option.styles.style"
                            [ngClass]="fields.material_filter_option.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material_filter_option.label + (fields.material_filter_option.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material_filter_option.label}}<span *ngIf="fields.material_filter_option.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <InventoryCounts-material_filter_options_dd_single 
                            data-cy="selector"
                            [type]="fields.material_filter_option.control.type"
                            formControlName="material_filter_option"
                            (displayTextChange)="fields.material_filter_option.control.displayText=$event"
                            [placeholder]="fields.material_filter_option.control.placeholder"
                            [styles]="fields.material_filter_option.control.styles"
                            [tooltip]="fields.material_filter_option.control.tooltip"
                        >
                        </InventoryCounts-material_filter_options_dd_single>
                        <ng-container *ngIf="fields.material_filter_option.invalid">
                          <ng-container *ngFor="let error of fields.material_filter_option.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-select_materials" *ngIf="!fields.select_materials.hidden" 
                            class="field-container full {{fields.select_materials.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.select_materials.styles.style"
                            [ngClass]="fields.select_materials.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.select_materials.label + (fields.select_materials.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.select_materials.label}}<span *ngIf="fields.select_materials.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.select_materials.control.readOnly"
                                [ngStyle]="fields.select_materials.control.styles.style"
                                [ngClass]="fields.select_materials.control.styles.classes"
                                [matTooltip]="fields.select_materials.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_select_materials_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.select_materials.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.select_materials.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.select_materials.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.select_materials.invalid">
                          <ng-container *ngFor="let error of fields.select_materials.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-select_vendorlots" *ngIf="!fields.select_vendorlots.hidden" 
                            class="field-container full {{fields.select_vendorlots.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.select_vendorlots.styles.style"
                            [ngClass]="fields.select_vendorlots.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.select_vendorlots.label + (fields.select_vendorlots.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.select_vendorlots.label}}<span *ngIf="fields.select_vendorlots.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.select_vendorlots.control.readOnly"
                                [ngStyle]="fields.select_vendorlots.control.styles.style"
                                [ngClass]="fields.select_vendorlots.control.styles.classes"
                                [matTooltip]="fields.select_vendorlots.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_select_vendorlots_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.select_vendorlots.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.select_vendorlots.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.select_vendorlots.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.select_vendorlots.invalid">
                          <ng-container *ngFor="let error of fields.select_vendorlots.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
        <div class="widgets">
          <div *ngIf="!widgets.count_tasks_progress.hidden" class="widget-container">
            <InventoryCounts-count_tasks_progress_widget
            #$widgets_count_tasks_progress 
              [inventoryCountId]="$widgets_count_tasks_progress_inParams_inventoryCountId"
            ($refreshEvent)="refresh(false, false, '$widgets_count_tasks_progress')"
            >
            </InventoryCounts-count_tasks_progress_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inventory_count_tasks.hidden" class="tab" data-cy="tab-inventory_count_tasks">
              <h2 [className]="tabs.inventory_count_tasks.active? 'active': ''" (click)="tabs.inventory_count_tasks.activate()">{{tabs.inventory_count_tasks.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-count_tasks_grid *ngIf="tabs.inventory_count_tasks.active"
              #$tabs_inventory_count_tasks
              [inventoryCountId]="$tabs_inventory_count_tasks_count_tasks_grid_inParams_inventoryCountId"
              [allowEdit]="$tabs_inventory_count_tasks_count_tasks_grid_inParams_allowEdit"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory_count_tasks')">
              </FootPrintManager-count_tasks_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>